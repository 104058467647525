import React from 'react'
import {
  BooleanInput,
  Create, maxLength, minLength, NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <TextInput source="name" validate={[required, maxLength(50), minLength(1)]}/>
      <BooleanInput source="recommended" validate={required} defaultValue={true}/>
      <NumberInput source="order"/>
    </SimpleForm>
  </Create>
)
