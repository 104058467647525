import React from 'react'

import {DateField, ReferenceField, SelectField, Show, SimpleShowLayout, TextField, translate,} from 'react-admin'
import DeleteActions from "../../custom/common/DeleteActions"

class ReportShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
        <Show {...props} actions={<DeleteActions/>}>
          <SimpleShowLayout>
            <p>[주의]삭제 버튼은 신고된 글을 삭제합니다. 신고 내역은 삭제하지 않습니다.</p>
            <ReferenceField label="신고자명" source="userId" reference="users" linkType='show'>
              <TextField source="name" />
            </ReferenceField>

            <TextField source="targetName"/>
            <TextField source="targetId"/>
            <TextField source="targetContent"/>

            <TextField source="title"/>
            <TextField source="description"/>

            <SelectField source="category" choices={[
              {id: 'ad', name: '광고'},
              {id: 'spam', name: '스팸'},
              {id: 'inappropriateContent', name: '욕설/비방/혐오'},
            ]} sortable={false}/>
            <DateField source="createdAt" locales='ko' showTime/>
          </SimpleShowLayout>
        </Show>
    )
  }
}

export default translate(ReportShow)
