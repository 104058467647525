import React from 'react'
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  ImageField,
  ImageInput,
  LongTextInput,
  maxLength,
  minLength,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'

import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

const fullPageHrStyle = {
  width: '100%',
  borderTop: '1px solid #ccc', // 변경 가능한 가로선 스타일
  margin: '16px 0'
}

export default (props) => (
  <Edit {...props} actions={<EditActions />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="title" validate={[required, maxLength(100), minLength(1)]} />
      <TextInput source="subTitle" validate={[required, maxLength(100), minLength(1)]} />
      <ImageInput source="titleImagePath" accept="image/*" validate={[required]}>
        <ImageField source="image" title="title" />
      </ImageInput>
      <BooleanInput source="onOff" validate={[required]} defaultValue={false} />
      <BooleanInput source="isAd" validate={[required]} defaultValue={false} />
      <SelectInput source="type" choices={[{id: 'click', name: '클릭 형태'}]} />
      <br />
      <hr style={fullPageHrStyle} />
      <br />
      <SelectInput source="rewardType" choices={[{id: 'point', name: '포인트'}]} />
      <ReferenceInput
        label="클릭 보너스 이미지"
        source="rewardImage"
        reference="designSource"
        filter={{detailCategory: 'offerwallImage'}}
        validate={required}
        perPage={200}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <LongTextInput label="추가적인 내용을 입력해주세요." source="rewardValue" validate={[required, minLength(1)]} />
      <br />
      <hr style={fullPageHrStyle} />
      <br />
      <SelectInput
        source="landingType"
        choices={[
          {id: 'events', name: '이벤트'},
          {id: 'notice', name: '공지사항'},
          {id: 'exhibition', name: '기획전'},
          {id: 'community', name: '커뮤니티 상세페이지'},
          {id: 'url', name: '링크'},
          {id: 'image', name: '이미지'},
          {id: 'walk', name: '산책'},
          {id: 'myPage', name: '마이 페이지'},
          {id: 'seedCampaignMain', name: '체험단 메인'},
          {id: 'postingCommunity', name: '커뮤니티 게시글 상세'},
          {id: 'postingNotice', name: '공지사항 게시글 상세'},
          {id: 'postingEvent', name: '이벤트 게시글 상세'},
          {id: 'shoppingExhibition', name: '쇼핑 기획전'}
        ]}
        validate={[required]}
      />
      <LongTextInput label="추가적인 내용을 입력해주세요." source="landingValue" validate={[required, minLength(1)]} />
      <br />
      <DateTimeInput source="startDate" locales="ko" validate={[required]} defaultValue={new Date()} />
      <DateTimeInput source="endDate" locales="ko" validate={[required]} defaultValue={new Date()} />
    </SimpleForm>
  </Edit>
)
