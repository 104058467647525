import React from 'react'
import {
    Create,
    DateInput,
    NumberInput,
    SimpleForm,
    TextInput,
    minValue,
    maxValue,
    SelectInput,
    ReferenceInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    maxLength,
    minLength
} from 'react-admin'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import {required} from "../../validators"

export default (props) => (
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm toolbar={<CreateToolbar/>}>
        <ReferenceInput label="회사" source="sellerId" reference="sellers" validate={[required]} perPage={150}>
          <SelectInput optionText="name"/>
        </ReferenceInput>

        <TextInput source="title" validate={[required]}/>
        <NumberInput source="point" validate={[required, minValue(0)]}/>
        <BooleanInput label='지정 코드' source="isManualCode" defaultValue={false} validate={[required]}/>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              return (
                  <>
                      {!formData.isManualCode &&
                      <NumberInput label='개수(최대 50)' source="count" validate={[required, minValue(1), maxValue(50)]}/>
                      }
                  </>
              )
              }
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              return (
                  <>
                      {formData.isManualCode &&
                      <ArrayInput label='생성할 코드(유일해야 함)' source="manualCodes" validate={required}>
                          <SimpleFormIterator>
                              <TextInput label="코드" source="codeItem" validate={[required, maxLength(12), minLength(3)]}/>
                          </SimpleFormIterator>
                      </ArrayInput>
                      }
                  </>
              )
              }
            }
          </FormDataConsumer>

          <DateInput source="startDate" locales='ko' validate={[required]}/>
          <DateInput source="endDate" locales='ko' validate={[required]}/>
      </SimpleForm>
    </Create>
)
