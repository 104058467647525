import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {CardActions, Datagrid, DateField, List, TextField, Filter, ReferenceField, BooleanField} from 'react-admin'
import SeedCampaignInquiryButton from './ListExcelExportButton'
const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

// const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)
const Actions = ({basePath, ...props}) => (
  <CardActions>
    <SeedCampaignInquiryButton {...props} />
  </CardActions>
)

const ListFilter = (props) => <Filter {...props}></Filter>

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <BooleanField source="isAnswered" />
        <TextField source="seedCampaignId" sortable={false} />
        <ReferenceField
          label="체험단 문의 카테고리"
          source="seedCampaignInquiryCategoryId"
          reference="seedCampaignInquiryCategory"
          linkType={'show'}
          perPage={300}
        >
          <TextField source="name" />
        </ReferenceField>

        <TextField source="userId" sortable={false} />
        <TextField source="description" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
