import React from 'react'
import {Create, NumberInput, SimpleForm, TextInput,} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm
          toolbar={<CreateToolbar/>}>
        <TextInput source="name" validate={required}/>
        <NumberInput source='order'defaultValue={1000} validate={required}/>

      </SimpleForm>
    </Create>
)
