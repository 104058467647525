import React from 'react'
import {
  Edit,
  FormDataConsumer,
  ImageField,
  maxLength,
  SelectInput,
  SimpleForm,
  ImageInput,
  TextInput
} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => {
  return (
    <Edit {...props} actions={<EditActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name" defaultValue={''} validate={[required, maxLength(20)]} />
        <ImageInput source="titleImage" accept="image/*" validate={required}>
          <ImageField source="image" title="title" />
        </ImageInput>
        <FormDataConsumer>
          {({formData, ...rest}) => {
            return (
              <>
                <SelectInput
                  source="category"
                  label="대분류"
                  choices={[
                    {id: 'seedCampaign', name: '체험단'},
                    {id: 'banners', name: '배너'},
                    {id: 'popups', name: '팝업'},
                    {id: 'offerwall', name: '오퍼월'}
                  ]}
                  validate={required}
                />
                <br />
                <SelectInput
                  source="detailCategory"
                  label="소분류"
                  choices={[
                    {id: 'channelPopupImage', name: '채널별 팝업 공지'},
                    {id: 'additionalTitleImage', name: '캡션 이미지'},
                    {id: 'offerwallBannerImage', name: '오퍼월 배너 이미지'},
                    {id: 'offerwallPopupImage', name: '오퍼월 팝업 이미지'},
                    {id: 'offerwallImage', name: '오퍼월 이미지'}
                  ]}
                  validate={required}
                />
              </>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}
