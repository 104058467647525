import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  ImageField,
  List,
  NumberField,
  ReferenceField,
  SearchInput,
  SelectInput,
  TextField
} from 'react-admin'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const Actions = ({basePath, props}) => {
  const permissions = localStorage.getItem('auth')
  return (
    <CardActions>
      {/*<SeedCampaignListExcelExportButton {...props} />*/}
      {permissions !== 'manager' ? <CreateButton basePath={basePath} /> : null}
    </CardActions>
  )
}

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="체험단 ID / TITLE" source="search" alwaysOn />
    <SelectInput
      label="채널 종류"
      source="channel"
      choices={[
        {id: 'instagram', name: '인스타그램'},
        {id: 'blog', name: '블로그'},
        {id: 'youtube', name: '유튜브'},
        {id: 'purchaseReview', name: '구매평'}
      ]}
      alwaysOn
    ></SelectInput>
    <DateInput label="모집 시작일(FROM)" source="recruitmentStartAt" alwaysOn />
    {/*<DateInput label="모집 시작일(TO)" source="recruitmentStartAt" alwaysOn />*/}
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <ThumbnailImageField source="titleImage" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="channel" sortable={false} />

        <NumberField source="totalReadCount" sortable={false} />
        <NumberField source="applyCount" sortable={false} />
        <NumberField source="recruitmentCount" sortable={false} />

        <ReferenceField
          label="광고주"
          source="seedCampaignAdvertiserId"
          reference="seedCampaignAdvertiser"
          linkType={'show'}
          perPage={100}
        >
          <TextField source="name" />
        </ReferenceField>

        <DateField source="recruitmentStartAt" locales="ko" showTime sortable={false} />
        <DateField source="recruitmentEndAt" locales="ko" showTime sortable={false} />
        <DateField source="registrationEndAt" locales="ko" showTime sortable={false} />

        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
