import React, {useState} from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button, GET_ONE, Loading} from 'react-admin'

import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const EntryUserListExcelExportButton = ({record}) => {
  const [loading, setLoading] = useState(false)

  const excelExport = async () => {
    try {
      // 여기에 엑셀 정보를 차곡차곡 쌓음, 첫 array는 엑셀의 제목
      setLoading(true)

      //const excelData = [['ID', '유저ID', '닉네임', '게시글ID', '댓글 내용','작성일']]
      const excelData = [['ID', 'User', '내용', '생성일']]
      // 최초 1회는 그냥 가져옴 (total값이 있어야 반복 가능)
      const getEventData = await dataProvider('ENTRY', 'events', {id:record.id})

      // 가져온 데이터를 쌓음
      const rows = getEventData.data.participation

      for (let i = 0; i < rows.length; i++) {
        const {id, content, userId, createdAt} = rows[i]

        const getUserInfo = await dataProvider(GET_ONE, 'users', {id: userId})
        const {name} = getUserInfo.data
        excelData.push([id, name, content, new Date(createdAt).toLocaleString()])
      }

      setLoading(false)
      excelDownload(excelData, `참여 내역 전체 리스트 - ${new Date().toLocaleString()}.xlsx`)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Button label="참여 내역 전체 리스트 다운로드" onClick={excelExport}>
        {loading ? (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%'
            }}
          >
            <Loading style={{position: 'relative', zIndex: '1000'}} className="target" />
          </div>
        ) : (
          <ImportExportIcon />
        )}
      </Button>
    </>
  )
}

export default EntryUserListExcelExportButton
