import BookIcon from '@material-ui/icons/Book'
import ProductCreate from './ProductCreate'
import ProductEdit from './ProductEdit'
import ProductShow from './ProductShow'
import ProductList from './ProductList'

export default {
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  show: ProductShow,
  icon: BookIcon
}
