import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  List,
  SearchInput,
  SelectField,
  TextField,
} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})
const Actions = ({basePath,}) => (
  <CardActions>
    <CreateButton basePath={basePath}/>
  </CardActions>
)

const ListFilter = props => (
    <Filter {...props}>
      <SearchInput label={props.translate('resources.productReviewReports.fields.title')} source="search" alwaysOn/>
    </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          actions={<Actions/>}
          perPage={25}
          filters={<ListFilter/>}
          bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <TextField source="reviewId" sortable={false}/>
        <TextField source="isReviewDelete" sortable={false}/>
        <TextField source="userId" sortable={false}/>
        <TextField source="title" sortable={false}/>
        <TextField source="description" sortable={false}/>
        <SelectField source="category" choices={[
          {id: 'ad', name: '광고'},
          {id: 'spam', name: '스팸'},
          {id: 'inappropriateContent', name: '욕설/비방/혐오'},
        ]} sortable={false}/>
        <DateField source="createdAt" locales='ko' showTime sortable={false}/>
      </Datagrid>
    </List>
  )
})

