import React from 'react'

import {
  ArrayField,
  CardActions,
  CardContentInner,
  Datagrid,
  DatagridBody,
  ImageField,
  ListButton,
  NumberField,
  BooleanField,
  ReferenceField,
  SelectField,
  ShowController,
  SimpleShowLayout,
  TextField,
  TitleForRecord,
  translate
} from 'react-admin'
import {withStyles} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import OrderReceiver from './OrderReceiver'
import OrderStatus from './OrderStatus'
import OrderUser from './OrderUser'
import OrderInfoButton from './OrderInfoButton'
import OrderMemoButton from './OrderMemoButton'
import OrderStatusButton from './OrderStatusButton'
import choices from './choices'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import OrderProductStatusButton from './OrderProductStatusButton'

const ProductDatagridRow = ({
  record,
  resource,
  selected,
  id,
  onToggleItem,
  children,
  basePath,
  handleCheckBox,
  selectedIds
}) => {
  return (
    <TableRow key={id}>
      <TableCell padding="none" variant="head">
        <Checkbox checked={selected} onClick={() => onToggleItem(id)} />
      </TableCell>
      {React.Children.map(children, (field) => {
        return (
          <TableCell padding="dense" key={`${id}-${field.props.source}`}>
            {React.cloneElement(field, {
              record,
              basePath,
              resource
            })}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const ProductDatagridBody = ({handleCheckBox, ...props}) => <DatagridBody {...props} row={<ProductDatagridRow />} />

const ProductDatagrid = (props) => {
  return <Datagrid {...props} body={<ProductDatagridBody />} />
}

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const styles = {
  root: {display: 'flex', alignItems: 'flex-start'},
  form: {flexGrow: 2, marginRight: '2em'},
  container: {width: '35em'}
}

class OrderShow extends React.Component {
  state = {selectedIds: []}

  onToggleItem = (id) => {
    if (this.state.selectedIds.indexOf(id) === -1) {
      this.setState({selectedIds: [...this.state.selectedIds, id]})
    } else {
      this.setState({selectedIds: this.state.selectedIds.filter((selectedId) => selectedId !== id)})
    }
  }

  handleActionDone = () => {
    this.setState({selectedIds: []})
  }

  handleAllSelect = (ids) => {
    this.setState({selectedIds: ids})
  }

  render() {
    const ShowActions = ({basePath, ...props}) => {
      const permissions = localStorage.getItem('auth')
      const selectedIds = this.state.selectedIds.map((item) => JSON.parse(item).productOrderId)
      return (
        <CardActions>
          {permissions !== 'manager' && props.data && (
            <OrderProductStatusButton
              basePath={basePath}
              record={props.data}
              selectedIds={selectedIds}
              onDone={this.handleActionDone}
            />
          )}

          {permissions !== 'manager' && (
            <OrderMemoButton
              basePath={basePath}
              record={props.data}
              selectedIds={selectedIds}
              onDone={this.handleActionDone}
            />
          )}

          {permissions !== 'manager' && props.data && (
            <OrderInfoButton
              basePath={basePath}
              record={props.data}
              selectedIds={selectedIds}
              onDone={this.handleActionDone}
            />
          )}

          {permissions !== 'manager' && props.data && (
            <OrderStatusButton
              basePath={basePath}
              record={props.data}
              selectedIds={selectedIds}
              onDone={this.handleActionDone}
            />
          )}

          <ListButton basePath={basePath} />
        </CardActions>
      )
    }

    const {classes, translate, ...props} = this.props
    return (
      <ShowController {...props}>
        {({isLoading, defaultTitle, ...controllerProps}) => {
          const record = controllerProps.record
          return controllerProps.record ? (
            <div className={classes.root}>
              <Card className={classes.form}>
                <TitleForRecord defaultTitle={defaultTitle} record={record} />

                <CardContentInner>
                  <ShowActions basePath={controllerProps.basePath} data={record} />
                </CardContentInner>

                <SimpleShowLayout {...controllerProps}>
                  <TextField source="id" />
                  {record.userId && (
                    <ReferenceField source="userId" reference="users" linkType={'show'}>
                      <TextField source="name" />
                    </ReferenceField>
                  )}

                  <ArrayField source="products" label={'상품'}>
                    <ProductDatagrid
                      selectedIds={this.state.selectedIds}
                      onToggleItem={this.onToggleItem}
                      onSelect={this.handleAllSelect}
                      hasBulkActions={true}
                    >
                      <TextField source="productOrderId" sortable={false} />

                      <ReferenceField label="상품ID" source="id" reference="products" linkType={'show'}>
                        <TextField source="id" />
                      </ReferenceField>

                      <ThumbnailImageField
                        label={translate('resources.products.fields.titleImagePath')}
                        source="titleImagePath"
                        sortable={false}
                      />

                      <TextField label={translate('resources.products.fields.name')} source="name" sortable={false} />

                      <TextField label={'옵션ID'} source="optionId" sortable={false} />
                      <TextField label={'옵션명'} source="optionName" sortable={false} />

                      <NumberField label={'제품가'} source="productSalePrice" sortable={false} />
                      <NumberField label={'옵션가'} source="optionSalePrice" sortable={false} />

                      <NumberField
                        label={translate('resources.orders20.fields.quantity')}
                        source="quantity"
                        sortable={false}
                      />
                      <NumberField label={'합계'} source="totalPrice" sortable={false} />
                      <BooleanField label={'핫딜'} source="isHotDeal" sortable={false} />

                      <SelectField label="상태" source="status" choices={choices.status} sortable={false} />
                      <SelectField label="택배사" source="courierCode" choices={choices.couriers} sortable={false} />
                      <TextField label="송장번호" source="trackingNumberStr" sortable={false} />
                      <BooleanField label="당일배송" source="isTodayOrder" sortable={false} />

                      <TextField source="adminMemo" sortable={false} />
                    </ProductDatagrid>
                  </ArrayField>

                  <SelectField source="method" choices={choices.methods} />
                  <NumberField source="deliveryFee" />
                  <NumberField source="point" />
                  <NumberField source="amount" />
                  {record.vbank && (
                    <TextField label={translate('resources.orders20.fields.vbank.name')} source="vbank.bank" />
                  )}
                  {record.vbank && (
                    <TextField label={translate('resources.orders20.fields.vbank.holder')} source="vbank.P_VACT_NAME" />
                  )}
                  {record.vbank && (
                    <TextField label={translate('resources.orders20.fields.vbank.number')} source="vbank.P_VACT_NUM" />
                  )}
                  {record.vbank && (
                    <TextField label={translate('resources.orders20.fields.vbank.expire')} source="vbank.expire" />
                  )}
                  {record.vbankRefund && <TextField source="vbankRefund.bank" />}
                  {record.vbankRefund && <TextField source="vbankRefund.holder" />}
                  {record.vbankRefund && <TextField source="vbankRefund.account" />}
                  <TextField source="memo" />
                </SimpleShowLayout>
              </Card>
              <div style={{display: 'block'}}>
                <OrderStatus record={record} />
                <OrderUser record={record} />
                <OrderReceiver record={record} />
              </div>
            </div>
          ) : (
            ''
          )
        }}
      </ShowController>
    )
  }
}

export default withStyles(styles)(translate(OrderShow))
