import React from 'react';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {Button,} from 'react-admin';
import {excelDownload} from "../../custom/common/excelDownload"
import {dataProvider} from '../../App'

const UserExcelExportButton = props => {
  const excelExport = async () => {
    const bulkData = props.selectedIds.map((value) => {
      return props.data[value]
    })
    const data = [['ID', '닉네임', '포인트','소멸예정 포인트', '번호', '이메일', '마케팅 동의 여부', '추천인 ID',
      '추천인 코드 제목',
      '산책수', '포스팅수', '구매횟수', '구매가격',
      '가입일']]

    for (let i = 0; i < bulkData.length; i++) {
      let redeemData
      if (bulkData[i].redeemCodeId) {
        redeemData = await dataProvider('GET_CODE', 'redeems', {id: bulkData[i].redeemCodeId})
        bulkData[i].redeemCode = redeemData.data.title
      }
    }

    bulkData.forEach((user, index) => {
      const accounts = user.accounts.email
      data.push([
        user.id.toString(), user.name, user.point, user.expirePoint,
        user.phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,'$1-$2-$3'),
        accounts, user.agreeMarketing ? 'O' : 'X', user.referrerId, user.redeemCode,
        user.walkCount, user.postCount, user.purchaseCount, user.purchasePrice,
        new Date(user.createdAt).toLocaleString()
      ])
    })
    excelDownload(data, '유저 목록')
  }
  return (
      <Button
          label="Select Excel"
          onClick={excelExport}
      >
        <ImportExportIcon/>
      </Button>
  );
};

export default UserExcelExportButton;

