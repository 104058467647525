import React from 'react'
import {
  CardActions,
  DateField,
  Show,
  TextField,
  translate,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  EditButton,
  NumberField,
  BooleanField
} from 'react-admin'

import ImageClickField from '../../custom/common/ImageClickField'

const ShowActions = ({basePath, data, ...props}) => {
  return (
    <CardActions>
      <EditButton basePath={basePath} record={data} />
    </CardActions>
  )
}

class SeedCampaignBestReviewShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout>
          <Tab label={'기본 정보'}>
            <TextField source="id" />

            <ReferenceField label="체험단 이름" source="seedCampaignId" reference="seedCampaign" linkType="show">
              <TextField optionText="title" />
            </ReferenceField>

            <ReferenceField label="사용자 이름" source="userId" reference="users" linkType="show">
              <TextField source="name" />
            </ReferenceField>

            <TextField source="connectedUrl" />

            <ImageClickField label="대표 이미지" addLabel={true} source="titleImage" src="image" />

            <br />
            <NumberField source="displayOrder" />
            <BooleanField source="isActive" />
            <TextField label="* 비활성화 시 체험단 메인 화면에서 '베스트 리뷰' 영역에 미표출됩니다." />
            <DateField source="createdAt" />
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(SeedCampaignBestReviewShow)
