import React, {Component} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {translate} from 'react-admin'
import compose from 'recompose/compose'
import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment'
import choices from './choices'

const styles = {
  container: {width: '35em', marginBottom: 20},
  rightAlignedCell: {textAlign: 'right'},
  boldCell: {fontWeight: 'bold'},
}

const paymentStatus = [{id: 'registered', name: '주문완료'},...choices.status]

class UserInspection extends Component {
  render() {
    const {classes, record} = this.props
    return record.statusLog ? <Paper className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>주문상태</TableCell>
            <TableCell>날짜</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.statusLog.map((s, i) => (
            <TableRow key={i}>
              <TableCell>{paymentStatus.find(({id}) => id === s.status).name}</TableCell>
              <TableCell style={{whiteSpace: 'pre', lineHeight: 1.6}}>
                {moment(s.createdAt).format('YYYY-MM-DD A hh:mm')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper> : ''
  }

}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(UserInspection)
