import BookIcon from '@material-ui/icons/Book'
import SeedCampaignBestReviewShow from './SeedCampaignBestReviewShow'
import SeedCampaignBestReviewEdit from './SeedCampaignBestReviewEdit'
import SeedCampaignBestReviewList from './SeedCampaignBestReviewList'
import SeedCampaignBestReviewCreate from './SeedCampaignBestReviewCreate'

export default {
  create: SeedCampaignBestReviewCreate,
  edit: SeedCampaignBestReviewEdit,
  list: SeedCampaignBestReviewList,
  show: SeedCampaignBestReviewShow,
  icon: BookIcon
}
