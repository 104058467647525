import React, {useState} from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button, Loading} from 'react-admin'

import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const ApplicantListExcelExportButton = ({record}) => {
  const [loading, setLoading] = useState(false)
  const excelExport = async () => {
    try {
      // 여기에 엑셀 정보를 차곡차곡 쌓음, 첫 array 는 엑셀의 제목
      setLoading(true)
      const excelData = [
        [
          '신청테이블 ID',
          '신청 유저 ID',
          '신청 유저 닉네임',
          '상태',
          '선정 여부',
          '확인 여부',
          '채널',
          'URL',
          '배송정보 - 이름',
          '배송 정보 - 연락처',
          '배송 정보 - 우편번호',
          '배송 정보 - 주소 / 상세',
          '유저 성별',
          '유저 나이대',
          '반려동물 구분',
          '반려동물 정보',
          '신청날짜',
          '업데이트날짜'
        ]
      ]
      // 최초 1회는 그냥 가져옴 (total 값이 있어야 반복 가능)
      const getApplicantData = await dataProvider('APPLICANT', 'seedCampaign', {id: record.id})
      // 가져온 데이터를 쌓음
      const rows = getApplicantData.data
      for (let i = 0; i < rows.length; i++) {
        const {
          id,
          userId,
          name,
          status,
          isSelected,
          isConfirmed,
          channel,
          url,
          userName,
          userPhone,
          userZipCode,
          userAddress,
          userAddressDetail,
          userGender,
          userAge,
          petType,
          petInformation,
          createdAt,
          updatedAt
        } = rows[i]
        excelData.push([
          id,
          userId,
          name,
          status,
          isSelected,
          isConfirmed,
          channel,
          url,
          userName,
          userPhone,
          userZipCode,
          userAddress + '  ' + userAddressDetail,
          userGender,
          userAge,
          petType,
          petInformation,
          new Date(createdAt).toLocaleString(),
          new Date(updatedAt).toLocaleString()
        ])
      }
      setLoading(false)
      excelDownload(excelData, `${record.id}_신청_${record.title}.xlsx`)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Button label="신청 내역 전체 리스트 다운로드" onClick={excelExport}>
        {loading ? (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%'
            }}
          >
            <Loading style={{position: 'relative', zIndex: '1000'}} className="target" />
          </div>
        ) : (
          <ImportExportIcon />
        )}
      </Button>
    </>
  )
}

export default ApplicantListExcelExportButton
