import React from 'react'
import {BooleanInput, Create, NumberInput, SimpleForm, TextInput} from 'react-admin'

import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions />}>
    <SimpleForm toolbar={<CreateToolbar />}>
      <TextInput source="name" validate={required} />
      <BooleanInput source="isActive" defaultValue={false} validate={required} />
      <NumberInput source="displayOrder" defaultValue={1000} validate={required} />
    </SimpleForm>
  </Create>
)
