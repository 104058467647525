import BookIcon from '@material-ui/icons/Book'
import SellerNoticeCreate from './SellerNoticeCreate'
import SellerNoticeShow from './SellerNoticeShow'
import SellerNoticeEdit from './SellerNoticeEdit'
import SellerNoticeList from './SellerNoticeList'

export default {
  create: SellerNoticeCreate,
  edit: SellerNoticeEdit,
  list: SellerNoticeList,
  show: SellerNoticeShow,
  icon: BookIcon,
}
