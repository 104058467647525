import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {change, isSubmitting, submit} from 'redux-form'
import {BooleanInput, Button, fetchEnd, fetchStart, SaveButton, showNotification, SimpleForm} from 'react-admin'
import SendIcon from '@material-ui/icons/Send'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {dataProvider} from '../../App'
import {required} from '../../validators'

class UserNotificationButton extends Component {
  state = {
    error: false,
    showDialog: false,
  }

  handleClick = () => {
    this.setState({showDialog: true})
  }

  handleCloseClick = () => {
    this.setState({showDialog: false})
  }

  handleSaveClick = () => {
    const {submit} = this.props
    submit('post-quick-create')
  }

  handleSubmit = async values => {
    const {selectedIds} = this.props
    if(selectedIds.length <= 0) return
    for (let i=0; i < selectedIds.length; i++) {
      const apiValues = {id: selectedIds[i], agree: values.agreeMarketing}
      dataProvider('MARKETING', 'users', {data: apiValues})
        .then(({data}) => {})
        .catch(error => {
          this.setState({showDialog: false})
        })
        .finally(() => {
        })
    }

    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  render() {
    const {showDialog} = this.state
    const {isSubmitting} = this.props

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="마케팅 수신 변경">
          <SendIcon/>
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="마케팅 수신 변경">

          <DialogTitle>유저 PUSH</DialogTitle>
          <DialogContent>
            <SimpleForm
              form="post-quick-create"
              resource="posts"
              onSubmit={this.handleSubmit}
              toolbar={null}
            >
              <BooleanInput source="agreeMarketing" label="마케팅 수신 동의" isRequired={required} defaultValue={false} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick}/>
            <Button  onClick={this.handleCloseClick}>
              <IconCancel/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('post-quick-create')(state),
})

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNotificationButton)
