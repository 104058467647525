import React from 'react'
import {
  CardActions,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceField,
  SearchInput,
  // SelectInput,
  TextField
} from 'react-admin'
import ListExcelExportButton from './ListExcelExportButton'

const Actions = ({basePath, ...props}) => (
  <CardActions>
    <ListExcelExportButton {...props} />
  </CardActions>
)
const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="유저" source="user" alwaysOn />
    <SearchInput label="제목" source="search" alwaysOn />
    <DateInput label="시작 날짜" source="startDate" alwaysOn />
    <DateInput label="종료 날짜" source="endDate" alwaysOn />
    {/*<SelectInput*/}
    {/*  label="게시글 유형"*/}
    {/*  source="category"*/}
    {/*  choices={[*/}
    {/*    {id: 'event', name: '이벤트'},*/}
    {/*    {id: 'notice', name: '공지사항'}*/}
    {/*  ]}*/}
    {/*  alwaysOn*/}
    {/*/>*/}
  </Filter>
)

export const EventCommentsList = (props) => {
  return (
    <List
      {...props}
      actions={<Actions {...props} />}
      perPage={25}
      start={10}
      filters={<ListFilter />}
      bulkActions={true}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <ReferenceField source="userId" reference="users" linkType="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="eventId" label="이벤트 ID" />
        <TextField source="title" label="이벤트 제목" />
        <TextField source="content" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  )
}
