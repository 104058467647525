import React from 'react'
import SwapVerticalCircle from '@material-ui/icons/SwapVerticalCircle'
import {Button} from 'react-admin'
import {dataProvider} from '../../App'
import {customExcelDownload} from '../../custom/common/customExcelDownload'

const OrderCountListExcelExportButton = (props) => {
  const excelExport = async () => {
    const dataArr = [
      [
        'Index',
        '유저ID',
        '대주문번호',
        '소주문번호',
        '상품 번호',
        '상품명',
        '카테고리',
        '옵션명',
        '제품가',
        '옵션가',
        '수량',
        '주문금액',
        '배송비',
        '합계',

        '포인트',
        '결제금액',
        '결제수단',

        '주문자명',
        '연락처',
        'Email',
        '수령자명',
        '수령자연락처',
        '주소',
        '우편번호',
        '주문자메모',
        '주문생성일',
        '주문생성일 - 날짜',
        '주문생성일 - 시간',
        '주문상태',
        '업체명',
        '옵션ID',
        '송장번호',

        '발주일',
        '배송출발일',
        '배송완료일'
      ]
    ]

    const dataArr1 = [
      ['대주문번호', '유저ID', '유저닉네임', '주문횟수', '주문생성일 - 날짜', '주문생성일 - 시간', '주문생성일']
    ]
    const {
      data: {data, data1}
    } = await dataProvider('orderAccumulate', 'orders20')
    data.forEach((order, index) => {
      try {
        dataArr.push([
          index + 1,
          order.userId,
          String(order.orderId),
          String(order.id),
          order.productId,
          order.productName,
          order.category,
          order.optionName,
          order.productSalePrice,
          order.optionSalePrice,
          order.quantity,
          order.amount,
          order.deliveryFee,
          order.orderSum,

          order.point,
          order.paidAmount, //결제금액
          order.method,

          order.buyerName,
          order.buyerPhone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
          order.buyerEmail,
          order.receiverName,
          order.receiverTel.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
          order.address,
          String(order.zipCode),
          order.memo,
          new Date(order.createdAt).toLocaleString(),
          order.date,
          order.time,
          order.status,
          order.sellerName,
          order.optionId,
          order.trackingNumberStr == null ? String('') : String(order.trackingNumberStr),

          order.orderDate != null ? new Date(order.orderDate).toLocaleString() : '',
          order.sentDate != null ? new Date(order.sentDate).toLocaleString() : '',
          order.deliveryDate != null ? new Date(order.deliveryDate).toLocaleString() : ''
        ])
      } catch (e) {
        console.log(e)
      }
    })
    data1.forEach((order, index) => {
      try {
        dataArr1.push([
          String(order.orderId),
          order.userId,
          order.name,
          order.purchaseCount,
          order.date,
          order.time,
          new Date(order.createdAt).toLocaleString()
        ])
      } catch (e) {
        console.log(e)
      }
    })
    customExcelDownload(dataArr, '누적 주문 횟수 내역서', dataArr1)
  }
  return (
    <Button label="누적주문 리스트 다운로드" onClick={excelExport}>
      <SwapVerticalCircle />
    </Button>
  )
}
export default OrderCountListExcelExportButton
