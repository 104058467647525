import axios from 'axios'
import {isNull, omitBy} from 'lodash'

let isRefreshing = false
let refreshSubscribers = []
const instance = axios.create({baseURL: process.env.REACT_APP_URL})

const models = {
  admins: require('./admins'),
  home: require('./home'),
  designSource: require('./designSource'),
  auth: require('./auth'),
  users: require('./users'),
  talks: require('./talks'),
  places: require('./places'),
  breeds: require('./breeds'),
  reports: require('./reports'),
  files: require('./files'),
  points: require('./points'),
  facilities: require('./facilities'),
  services: require('./services'),
  products: require('./products'),
  placeBreeds: require('./placeBreeds'),
  placeKeywords: require('./placeKeywords'),
  categories: require('./categories'),
  communityCategory: require('./communityCategory'),
  categorySympathy: require('./categorySympathy'),
  knowledge: require('./knowledge'),
  reviews: require('./reviews'),
  events: require('./events'),
  eventsComments: require('./eventsComments'),
  exhibition: require('./exhibition'),
  banners: require('./banners'),
  deletedUsers: require('./deletedUsers'),
  deletedOrders: require('./deletedOrders'),
  orders20: require('./orders20'),
  dashboard: require('./dashboard'),
  comments: require('./comments'),
  notices: require('./notices'),
  noticeComments: require('./noticeComments'),
  sellers: require('./sellers'),
  redeems: require('./redeems'),
  messages: require('./messages'),
  balanceAccount: require('./balanceAccount'),
  productQAs: require('./productQAs'),
  productReviews: require('./productReviews'),
  productReviewReports: require('./productReviewReports'),
  ugcReports: require('./ugcReports'),
  popups: require('./popups'),
  sellerNotices: require('./sellerNotices'),
  advertising: require('./advertising'),
  seedCampaign: require('./seedCampaign'),
  seedCampaignAdvertiser: require('./seedCampaignAdvertiser'),
  seedCampaignInquiry: require('./seedCampaignInquiry'),
  seedCampaignInquiryCategory: require('./seedCampaignInquiryCategory'),
  seedCampaignBestReview: require('./seedCampaignBestReview'),
  offerwall: require('./offerwall'),
  donation: require('./donation'),
  donationDestination: require('./donationDestination'),
  donationGoods: require('./donationGoods'),
  pointExchange: require('./pointExchange')
}

instance.interceptors.response.use((response) => response, tokenErrorHandler)

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb)
}

function onRefreshed(token) {
  refreshSubscribers.forEach((cb) => cb(token))
  refreshSubscribers = []
}

async function tokenErrorHandler(error) {
  try {
    if (error.response.status !== 401) throw error
    const originalRequest = error.config

    const retryOriginalRequest = new Promise((resolve, reject) => {
      subscribeTokenRefresh((token) => {
        originalRequest.headers.authorization = `Bearer ${token}`
        resolve(axios(originalRequest))
      })
    })

    if (!isRefreshing) {
      isRefreshing = true
      const newToken = await refreshToken()
      isRefreshing = false
      onRefreshed(newToken)
    }
    return retryOriginalRequest
  } catch (e) {
    isRefreshing = false
    refreshSubscribers = []
    throw error
  }
}

async function refreshToken() {
  try {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    const path = '/admin/auth/refresh'

    const {data} = await axios.post(process.env.REACT_APP_URL + path, {accessToken, refreshToken})
    localStorage.setItem('accessToken', data.accessToken)
    return data.accessToken
  } catch (e) {
    throw e
  }
}

export default function () {
  const httpClient = (options = {}) => {
    if (!options.headers) options.headers = {}
    const token = localStorage.getItem('accessToken')
    if (token) options.headers.authorization = `Bearer ${token}`
    return instance(options)
  }

  return async function (type, resource, params) {
    const resourceModel = models[resource]
    const options = await resourceModel.request(type, omitBy(params, isNull))
    //console.log("++++>", type, resource, 'params>', params, 'options>', options)

    try {
      const response = await httpClient(options)
      //console.log("<----", type, resource, response)

      return resourceModel.response(response, type, params)
    } catch (e) {
      if (e.response.status === 409 && resource === 'users') {
        throw new Error('배송중이거나 결제완료 입니다.')
      }
      throw e.response
    }
  }
}
