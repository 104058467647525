import React from 'react'

import {BooleanField, DateField, NumberField, Show, SimpleShowLayout, TextField, translate,} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'

class PlaceKeywordShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
        <Show {...props} actions={<ShowActions/>}>
          <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <BooleanField source="recommended"/>
            <NumberField source="order"/>
            <DateField source="createdAt" locales='ko' showTime/>
          </SimpleShowLayout>
        </Show>
    )
  }
}

export default translate(PlaceKeywordShow)
