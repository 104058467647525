import React from 'react'
import {DateField, NumberField, Show, SimpleShowLayout, TextField, translate} from 'react-admin'
import ManagerShowActions from '../../custom/common/ManagerShowActions'
import {BooleanField} from 'ra-ui-materialui'
import {required} from '../../validators'

class HomeShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ManagerShowActions />}>
        <SimpleShowLayout>
          <NumberField source="id" defaultValue={1000} validate={required} />
          <TextField source="name" validate={required} />
          <BooleanField source="display" defaultValue={false} validate={[required]} />
          <DateField source="updatedAt" locales="ko" showTime sortable={false} />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(HomeShow)
