import React from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button, GET_ONE} from 'react-admin'
import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const PointExchangeListExcelExportButton = (props) => {
  const excelExport = async () => {
    const dataArr = [
      ['ID', '카테고리', '업체 종류', '상품명', '포인트 적용율', '할인율', '기존 가격', '가격', '판매중', '생성일']
    ]
    const excelData = await dataProvider('EXCEL', 'products')
    const data = excelData.data.data

    for (let i = 0; i < data.length; i++) {
      try {
        const dx = data[i]
        const seller = await dataProvider(GET_ONE, 'sellers', {id: dx.sellerId})

        dataArr.push([
          dx.id,
          dx.category,
          seller.data.name,
          dx.name,
          dx.usePoint,
          dx.discountRate,
          dx.originalPrice,
          dx.price,
          dx.onSale,
          new Date(dx.createdAt).toLocaleString()
        ])
      } catch (e) {
        console.log(e)
      }
    }

    excelDownload(dataArr, '전체 상품 관리 목록')
  }

  return (
    <Button label="전체 리스트 다운로드" onClick={excelExport}>
      <ImportExportIcon />
    </Button>
  )
}

export default PointExchangeListExcelExportButton
