import React from 'react'

import {
  BooleanField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  translate
} from 'react-admin'
import ImageClickField from "../../custom/common/ImageClickField"
import ManagerShowActions from "../../custom/common/ManagerShowActions"

class CategoryShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
        <Show {...props} actions={<ManagerShowActions/>}>
          <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <ImageClickField label='이미지' addLabel={true}
                             source="imagePath"/>
            <NumberField source='order'/>
            <BooleanField source="onSale"/>
          </SimpleShowLayout>
        </Show>
    )
  }
}

export default translate(CategoryShow)
