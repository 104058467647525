import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {
  BooleanField,
  CardActions,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  translate
} from 'react-admin'

import PhoneField from '../../custom/common/PhoneField'
import UserExcelExportButton from './UserExcelExportButton'
import UserListExcelExportButton from './UserListExcelExportButton'
import {dataProvider} from '../../App'
import UserNotificationButton from './UserNotificationButton'
import UserAgreeMarketing from './UserAgreeMarketing'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = (props) => (
  <CardActions>
    <UserAgreeMarketing {...props} />
    <UserNotificationButton {...props} />
    <UserListExcelExportButton {...props} />
    <UserExcelExportButton {...props} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="검색" source="search" alwaysOn />
    <TextInput label="추천인 ID 검색" source="referrerId" alwaysOn />
    <ReferenceInput source="redeemId" label="추천인 코드 검색" reference="redeems" alwaysOn>
      <SelectInput optionText="title" />
    </ReferenceInput>
    <DateInput label="시작 날짜" source="startDate" alwaysOn />
    <DateInput label="종료 날짜" source="endDate" alwaysOn />
  </Filter>
)
const CustomTextField = (props) => {
  const [title, setTitle] = useState(null)
  const [redeemId, setRedeemId] = useState(null)

  useEffect(() => {
    props.record.redeemCodeId &&
      dataProvider('GET_CODE', 'redeems', {id: props.record.redeemCodeId}).then((data) => {
        setTitle(data.data.title)
        setRedeemId(data.data.redeemId)
      })
  }, [props.record.redeemCodeId])
  return (
    <>
      {props.record.redeemCodeId && (
        <span
          style={{color: '#3f51b5', cursor: 'pointer'}}
          source="title"
          onClick={(e) => {
            props.history.push(`/redeems/${redeemId}/show`)
            e.stopPropagation()
          }}
        >
          {title}
        </span>
      )}
    </>
  )
}

export default translate(
  withStyles(styles)(({classes, ...props}) => {
    return (
      <List
        {...props}
        actions={<Actions />}
        filters={<ListFilter />}
        sort={{field: 'id', order: 'DESC'}}
        perPage={25}
        bulkActions={true}
      >
        <Datagrid rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="name" sortable={true} />
          <PhoneField source="phone" sortable={false} />
          <NumberField source="point" sortable={true} />

          <BooleanField source="agreeMarketing" sortable={true} />
          <ReferenceField source="referrerId" reference="users" allowEmpty linkType={'show'} sortable={true}>
            <TextField source="name" />
          </ReferenceField>
          <CustomTextField label={'추천인 코드 제목'} source="redeemCodeId" sortable={false} {...props} />
          <NumberField source="walkCount" sortable={false} />
          <NumberField source="postCount" sortable={false} />
          <NumberField source="commentCount" sortable={false} />
          <NumberField source="likeCount" sortable={false} />
          <NumberField source="friendsCount" sortable={false} />
          <NumberField source="purchaseCount" sortable={false} />
          <NumberField source="purchasePrice" sortable={false} />
          <DateField source="createdAt" sortable={true} locales="ko" showTime />
        </Datagrid>
      </List>
    )
  })
)
