import BookIcon from '@material-ui/icons/Book'
import BalanceCreate from './BalanceCreate'
import BalanceShow from './BalanceShow'
import BalanceList from './BalanceList'
import BalanceEdit from './BalanceEdit'

export default {
  list: BalanceList,
  create: BalanceCreate,
  edit: BalanceEdit,
  show: BalanceShow,
  icon: BookIcon
}
