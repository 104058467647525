import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  ImageField,
  List,
  NumberField,
  SearchInput,
  TextField
} from 'react-admin'
import NoticeListExcelExportButton from './NoticeListExcelExportButton'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const Actions = ({basePath, props}) => (
  <CardActions>
    <NoticeListExcelExportButton {...props} />
    <CreateButton basePath={basePath} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="아이디" source="searchId" alwaysOn />
    <SearchInput label={'제목'} source="search" alwaysOn />
    <DateInput label="시작 날짜" source="startDate" alwaysOn />
    <DateInput label="종료 날짜" source="endDate" alwaysOn />
  </Filter>
)

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)
export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={true} />
        <ThumbnailImageField source="titleImagePath" sortable={false} />
        <TextField source="title" sortable={false} />
        <NumberField source="totalReadCount" sortable={false} />
        <NumberField source="totalLikeCount" sortable={false} />
        <NumberField source="totalCommentCount" sortable={false} />
        <NumberField source="rolling_order" sortable={false} />
        <DateField source="createdAt" sortable={false} />
        <DateField source="startDate" sortable={false} />
        <DateField source="endDate" sortable={false} />
      </Datagrid>
    </List>
  )
})
