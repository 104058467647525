import React from 'react'
import {
  ArrayInput,
  DisabledInput,
  Edit,
  maxLength,
  maxValue,
  minLength,
  minValue,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from 'react-admin'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import {password, required, tel, userName} from '../../validators'

export default (props) => {
  return (
      <Edit {...props} actions={<EditActions/>}>
        <SimpleForm toolbar={<EditToolbar/>}>
          <DisabledInput source='id'/>
          <TextInput source="name" validate={[required, minLength(1), maxLength(100)]}/>
          <TextInput source="address" validate={[required]}/>
          <ArrayInput source="tels" validate={required}>
            <SimpleFormIterator>
              <TextInput label='번호' format={(value) => (typeof value === 'object' ? '' : value)}
                         validate={[required, tel]}/>
            </SimpleFormIterator>
          </ArrayInput>
          <NumberInput source="minimumPrice" defaultValue={0} validate={[required]}/>
          <NumberInput source="packingCount" defaultValue={1} validate={[required, minValue(1)]}/>
          <NumberInput source="todayFee" defaultValue={0}
                       validate={[required, minValue(0), maxValue(200000)]}/>

          <TextInput source="sellerId" validate={[required, userName]}/>
          <TextInput source="password" validate={[password]}/>
        </SimpleForm>
      </Edit>
  )
}