import React from 'react'
import {
  DisabledInput,
  Edit,
  SimpleForm,
  TextInput,
} from 'react-admin'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import {required} from "../../validators"

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <DisabledInput source='id'/>
      <TextInput source="name" validate={required}/>

    </SimpleForm>
  </Edit>
)
