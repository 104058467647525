import React from 'react'
import {
  Edit,
  ImageField,
  ImageInput,
  LongTextInput,
  maxLength,
  minLength,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  BooleanInput,
  NumberInput,
  Toolbar,
  SaveButton
} from 'react-admin'

import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import {Typography} from '@material-ui/core'
import {dataProvider} from '../../App'
import CustomDeleteButton from '../../custom/common/CustomDeleteButton'
import Button from '@material-ui/core/Button'
import PostAddIcon from '@material-ui/icons/Add'

const PopupEditToolbar = ({classes, history, ...props}) => {
  const handleClick = async () => {
    if (!props.invalid) {
      await dataProvider('DUPLICATE', 'popups', {data: props.record})
      history.push(props.basePath)
    }
  }
  return (
    <Toolbar {...props}>
      <SaveButton redirect="list" submitOnEnter={false} />
      <CustomDeleteButton {...props} submitOnEnter={false} variant="dense" />

      <Button
        startIcon={<PostAddIcon />}
        onClick={() => handleClick()}
        variant="contained"
        color="grey"
        style={{marginLeft: '15px', color: 'white', backgroundColor: 'Green'}}
      >
        복사
      </Button>
    </Toolbar>
  )
}
export default (props) => {
  return (
    <Edit {...props} actions={<EditActions />}>
      <SimpleForm toolbar={<PopupEditToolbar />}>
        <TextInput source="title" validate={[required, minLength(1), maxLength(50)]} />

        <SelectInput
          source="landing"
          choices={[
            {id: 'home', name: '홈'},
            {id: 'community', name: '커뮤니티'},
            {id: 'communityWalks', name: 'live산책인증'},
            {id: 'walk', name: '산책'},
            {id: 'shopping', name: '쇼핑'},
            {id: 'myPage', name: '마이페이지'}
          ]}
        />

        <SelectInput
          source="type"
          choices={[
            {id: 'image', name: '이미지'},
            {id: 'text', name: '텍스트'},
            {id: 'url', name: 'URL'},
            {id: 'home', name: '홈'},
            {id: 'community', name: '커뮤니티'},
            {id: 'communityWalks', name: 'live산책인증'},
            {id: 'walk', name: '산책'},
            {id: 'shopping', name: '쇼핑'},
            {id: 'myPage', name: '마이페이지'},
            {id: 'events', name: '기획전'},
            {id: 'notice', name: '게시글'}
          ]}
        />

        <FormDataConsumer>
          {({formData, ...rest}) => {
            return (
              <>
                {formData && formData.type === 'events' ? (
                  <ReferenceInput
                    label="기획전"
                    source="exhibitionId"
                    reference="exhibition"
                    validate={required}
                    perPage={200}
                  >
                    <SelectInput optionText="title" />
                  </ReferenceInput>
                ) : formData.type === 'image' ||
                  formData.type === 'text' ||
                  formData.type === 'walk' ||
                  formData.type === 'myPage' ||
                  formData.type === 'shopping' ||
                  formData.type === 'home' ||
                  formData.type === 'community' ||
                  formData.type === 'communityWalks' ? (
                  ''
                ) : (
                  <LongTextInput label="URL / 게시글 ID" source="exhibitionId" validate={[required, minLength(1)]} />
                )}
              </>
            )
          }}
        </FormDataConsumer>

        <LongTextInput source="content" validate={[minLength(1)]} />
        <ImageInput source="imagePath" accept="image/*">
          <ImageField source="image" title="title" />
        </ImageInput>
        <BooleanInput source="isAd" validate={[required]} defaultValue={false} />
        <BooleanInput source="isOfferwall" validate={[required]} defaultValue={false} />
        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (formData.isOfferwall) {
              return (
                <>
                  <NumberInput label="지급 포인트" source="rewardPoint" defaultValue={0} validate={required} />
                  <Typography variant="body2">
                    * 같은 팝업을 여러개 생성하셨다면 현재 시스템상 중복 적용이 안되어 수동으로 생성한 모든 동일 팝업에
                    포인트 적용을 해주셔야됩니다.
                  </Typography>
                </>
              )
            }
          }}
        </FormDataConsumer>

        <DateTimeInput source="startDate" locales="ko" validate={[required]} defaultValue={new Date()} />
        <DateTimeInput source="endDate" locales="ko" validate={[required]} defaultValue={new Date()} />
      </SimpleForm>
    </Edit>
  )
}
