import BookIcon from '@material-ui/icons/Book'
import TalkShow from './TalkShow'
import TalkEdit from './TalkEdit'
import TalkList from './TalkList'

export default {
  edit: TalkEdit,
  list: TalkList,
  show: TalkShow,
  icon: BookIcon
}
