import React, {useState} from 'react'
import ChatIcon from '@material-ui/icons/Chat'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {Button} from 'react-admin'
import {dataProvider} from '../../App'
import TextField from '@material-ui/core/TextField'

const CommentButton = ({record, match, type, history, ...props}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [content, setContent] = useState('')
  const handleSubmit = async () => {
    let options
    type === 'comment'
      ? options = {data: {content: content, userId: process.env.REACT_APP_PETP_ADMIN_ID}, id: record.id}
      : options = {
        data: {content: content, userId: process.env.REACT_APP_PETP_ADMIN_ID, parentId: record.id},
        id: record.noticeId
      }
    await dataProvider('COMMENT', 'notices', options)
    window.location.reload()
    setShowDialog(false)
  }
  return (
    <>
      <Dialog
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={showDialog}
      >
        <DialogTitle>댓글 입력</DialogTitle>
        <DialogContent>
          <TextField multiline={true} style={{width: '400px'}} onChange={e => setContent(e.target.value)}/>
          {/*<textarea onChange={e => setContent(e.target.value)}*/}
          {/*          style={{width: '400px', height: '100px', fontSize: '15px'}}/>*/}
        </DialogContent>
        <DialogActions>
          <Button label={'취소'} onClick={() => setShowDialog(false)} color="primary"/>
          <Button label={'등록'} onClick={() => handleSubmit()} color="primary"/>
        </DialogActions>
      </Dialog>
      <Button label={'댓글 등록'} onClick={() => setShowDialog(true)}>
        <ChatIcon/>
      </Button>
    </>
  )
}
export default CommentButton
