import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {CardActions, CreateButton, Datagrid, Filter, List, SearchInput, TextField,} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})
const Actions = ({basePath,}) => (
    <CardActions>
      <CreateButton basePath={basePath}/>
    </CardActions>
)

const ListFilter = props => (
    <Filter {...props}>
      <SearchInput label={'이름'} source="search" alwaysOn/>
    </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
      <List {...props}
            actions={<Actions/>}
            perPage={25}
            filters = {<ListFilter/>}
            bulkActions={false}>
        <Datagrid rowClick="show">
          <TextField source="id" sortable={true}/>
          <TextField source="name" sortable={false}/>
        </Datagrid>
      </List>
  )
})

