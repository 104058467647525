import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
    CardActions,
    CreateButton,
    Datagrid,
    DateField,
    Filter,
    ImageField,
    List,
    NumberField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField,
} from 'react-admin'

import PlaceListExcelExportButton from "./PlaceListExcelExportButton";

const styles = theme => ({
    title: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    }
})
const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const Actions = ({basePath, props}) => {
    const permissions = localStorage.getItem('auth')
    return (
        <CardActions>
            <PlaceListExcelExportButton {...props}/>
            {permissions !== 'manager' ? <CreateButton basePath={basePath}/> : null}
        </CardActions>
    )
}

const ListFilter = props => (
    <Filter {...props}>
        <SearchInput label={props.translate('resources.places.fields.name')} source="search" alwaysOn/>
        <SelectInput source="type" choices={[
            {id: 'dfangStore', name: '디팡 스토어'},
            {id: 'dfangMembers', name: '디팡 멤버스'},
            {id: 'normal', name: '일반'},
        ]} alwaysOn/>
        <ReferenceInput source="facilityId" label='편의시설/서비스' reference="facilities" {...props} perPage={200} alwaysOn>
            <SelectInput optionText='name'/>
        </ReferenceInput>
        <ReferenceInput source="serviceId" label='업종' reference="services" {...props} perPage={200} alwaysOn>
            <SelectInput optionText='name'/>
        </ReferenceInput>
        <ReferenceInput source="breedId" label='장소 견종' reference="placeBreeds" {...props} perPage={200} alwaysOn>
            <SelectInput optionText='name'/>
        </ReferenceInput>
        <ReferenceInput source="keywordId" label='장소 키워드' reference="placeKeywords" {...props} perPage={200} alwaysOn>
            <SelectInput optionText='name'/>
        </ReferenceInput>
        <SelectInput source="area" label={'지역'} choices={[
            {id: '서울', name: '서울'},
            {id: '경기', name: '경기'},
            {id: '인천', name: '인천'},
            {id: '대전', name: '대전'},
            {id: '충북', name: '충북'},
            {id: '충남', name: '충남'},
            {id: '전주', name: '전주'},
            {id: '광주', name: '광주'},
            {id: '목포', name: '목포'},
            {id: '전북', name: '전북'},
            {id: '전남', name: '전남'},
            {id: '대구', name: '대구'},
            {id: '울산', name: '울산'},
            {id: '부산', name: '부산'},
            {id: '경북', name: '경북'},
            {id: '경남', name: '경남'},
            {id: '제주', name: '제주'},

        ]} alwaysOn/>
    </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
    return (
        <List {...props}
              actions={<Actions/>}
              perPage={25}
              filters={<ListFilter/>}
              bulkActions={false}>
            <Datagrid rowClick="show">
                <TextField source="id" sortable={false}/>
                <ThumbnailImageField source="titleImagePath" sortable={false}/>
                <TextField source="name" sortable={false}/>
                <TextField source="address" sortable={false}/>
                <TextField source="tel" sortable={false}/>
                <NumberField source='order' sortable={false}/>
                <NumberField source='totalReadCount' sortable={false}/>
                <NumberField source='readCount' sortable={false}/>
                <NumberField source='totalLikeCount' sortable={false}/>
                <NumberField source='likeCount' sortable={false}/>
                <DateField source="createdAt" locales='ko' showTime sortable={false}/>
            </Datagrid>
        </List>
    )
})

