import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
    CardActions,
    Datagrid,
    DateField,
    List,
    TextField,
    ImageField,
    Filter,
    SearchInput, SelectInput, DateInput,
    RichTextField
} from 'react-admin'

import choices from "./choices";
import ListExcelExportButton from "./ListExcelExportButton"

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const Actions = ({basePath, ...props}) => (
    <CardActions>
        <ListExcelExportButton {...props}/>
    </CardActions>
)

const ListFilter = props => (
    <Filter {...props}>
        <SearchInput  source="search" label="상품명 검색" alwaysOn/>
        <SelectInput source="period" choices={choices.status} alwaysOn/>
        <DateInput source="startDate" label='시작 날짜' alwaysOn/>
        <DateInput source="endDate" label='종료 날짜' alwaysOn/>
    </Filter>
)


export default withStyles(styles)(({classes, ...props}) => {
  return (
      <List {...props}
            actions={<Actions/>}
            perPage={25}
            filters={<ListFilter/>}
            bulkActions={false}>

        <Datagrid rowClick="edit">
            <TextField source="id" label='ID' sortable={false}/>
            <TextField source="seller" label='업체명' sortable={false}/>
            <TextField source="name" sortable={false}/>
            <ThumbnailImageField source="thumbnail" label='대표 이미지' sortable={false}/>

            <TextField source="optionName" label='옵션' sortable={false}/>

            <TextField source="question" sortable={false}/>
            <TextField source="questionerId" label='문의자ID' sortable={false}/>

            <RichTextField source="answer" sortable={false}/>

            <DateField source='questionDate' locales='ko' />
            <DateField source='answerDate' locales='ko' />
        </Datagrid>
      </List>
  )
})
