import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {change, isSubmitting, submit} from 'redux-form'
import {
  AutocompleteInput,
  Button,
  fetchEnd,
  fetchStart,
  LongTextInput,
  ReferenceInput,
  SaveButton,
  showNotification,
  SimpleForm
} from 'react-admin'
import SendIcon from '@material-ui/icons/Send'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {dataProvider} from '../../App'
import {required} from '../../validators'

class MessagePush extends Component {
  state = {
    error: false,
    showDialog: false,
    showExcelInput: false,
    selectedUserId: null,
    message: '',
    userId: null,
  }

  handleClick = () => {
    this.setState({showDialog: true})
  }

  handleCloseClick = () => {
    this.setState({showDialog: false})
  }

  handleSaveClick = (values) => {
    const {submit} = this.props
    submit('post-quick-create')
  }

  handleChange = (e) =>{
    this.setState({showExcelInput: e.target.checked})
  }

  selectUser = (e) => {
    this.setState({selectedUserId: e.target.value})
  }

  handleSubmit = async values => {
    await dataProvider('CREATE', 'messages', {senderId: process.env.REACT_APP_PETP_ADMIN_ID, receiverId: values.id, text: values.message})
    window.location.reload()
  }


  render() {
    const {showDialog} = this.state
    const {isSubmitting} = this.props

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="메세지 보내기">
          <SendIcon/>
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="메세지 보내기">

          <DialogTitle>메세지 보내기</DialogTitle>
          <DialogContent>
            <SimpleForm
              form="post-quick-create"
              resource="posts"
              onSubmit={this.handleSubmit}
              toolbar={null}
            >
              <ReferenceInput
                filterToQuery={(searchText) => ({search: searchText})}
                source="id"
                reference="users"
                alwaysOn
                perPage={300}
                validate={required}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>

              <LongTextInput label='메세지 내용' source="message" validate={required} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick}/>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('post-quick-create')(state),
})

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagePush)
