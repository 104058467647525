import React from 'react';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {Button} from "react-admin";
import {dataProvider} from "../../App"
import {excelDownload} from "../../custom/common/excelDownload"

const PlaceListExcelExportButton = props => {
    const excelExport = async () => {
        const dataArr = [['ID', '추천 Pick', '분류', '업체명', '주소', '상세주소', '전화', '업체 소개',
            '주중 오픈 시간', '주중 마감 시간', '주말 오픈 시간', '주말 마감 시간', '휴무 요일',
            '홈페이지', '인스타그램', '카카오톡', '블로그',
            '디팡 할인', '쿠폰 사용', '포인트 사용', '우선 순위', '누적 조회 수', '조회 수', '누적 좋아요 수', '좋아요 수', '생성일']]
        const excelData = await dataProvider('EXCEL', 'places')
        const data = excelData.data.data
        data.forEach((place, index) => {
            try {
                dataArr.push([
                    place.id,
                    place.recommendPick,
                    place.type,
                    place.name,
                    place.address,
                    place.addressDetail,
                    place.tel,
                    place.description,
                    place.weekdayBusinessHourStart,
                    place.weekdayBusinessHourEnd,
                    place.weekendBusinessHourStart,
                    place.weekendBusinessHourEnd,
                    place.holiday,
                    place.homepage,
                    place.instagram,
                    place.kakaotalk,
                    place.blog,
                    place.dfangDiscount,
                    place.useCoupon,
                    place.usePoint,
                    place.order,
                    place.totalReadCount,
                    place.readCount,
                    place.totalLikeCount,
                    place.likeCount,
                    place.createdAt])
            } catch (e) {
                console.log(e)
            }
        })
        excelDownload(dataArr, '전체 장소 관리 목록')
    }
    return (
        <Button
            label="전체 리스트 다운로드"
            onClick={excelExport}
        >
            <ImportExportIcon/>
        </Button>
    );
};

export default PlaceListExcelExportButton;