import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  NumberField,
  SearchInput,
  SelectField,
  TextField
} from 'react-admin'
const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = ({basePath}) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
)
const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="아이디" source="searchId" alwaysOn />
    <SearchInput label={'제목'} source="search" alwaysOn />
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={true} />
        <SelectField
          source="type"
          choices={[
            {id: 'myPage', name: '마이페이지'},
            {id: 'image', name: '이미지'},
            {id: 'url', name: 'URL'},
            {id: 'walk', name: '산책'},
            {id: 'events', name: '기획전'}
          ]}
        />
        <TextField source="title" sortable={false} />

        <ThumbnailImageField source="thumbnailImage" sortable={false} />

        <NumberField source="totalReadCount" sortable={false} />
        {/*<NumberField source="readCount" sortable={false} />*/}
        <NumberField source="totalCommentCount" sortable={false} />
        {/*<NumberField source="commentCount" sortable={false} />*/}
        <NumberField source="order" sortable={true} />
        <DateField source="createdAt" locales="ko" sortable={false} />
      </Datagrid>
    </List>
  )
})
