export default ({
                  alwaysOn,
                  basePath,
                  component,
                  defaultValue,
                  formClassName,
                  initializeForm,
                  input,
                  isRequired,
                  label,
                  limitChoicesToValue,
                  locale,
                  meta,
                  options,
                  optionText,
                  optionValue,
                  record,
                  resource,
                  allowEmpty,
                  source,
                  textAlign,
                  translate,
                  translateChoice,
                  ...rest
                }) => rest;
