import React, {useState} from 'react'
import {
  Edit,
  DateTimeInput,
  FormDataConsumer,
  ImageField,
  LongTextInput,
  maxLength,
  SimpleForm, minLength,
} from 'react-admin'

import RichTextInput from 'ra-input-rich-text'
import {required} from "../../validators"
import EditActions from '../../custom/common/EditActions'
import EditToolbar from "../../custom/common/EditToolbar"
import OrderImageInput from "../../custom/common/DragOrderImage/OrderImageInput"

export default (props) => {
  const [imageData, setImageData] = useState([])
  return (
    <Edit {...props} actions={<EditActions/>}>
      <SimpleForm toolbar={<EditToolbar/>}>
        <LongTextInput label='제목' source="title" validate={[required, maxLength(50)]}/>

        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (imageData.length > 0) {
              formData.images = imageData
            }
            return (
              <OrderImageInput label='이미지' placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>} multiple
                setState={setImageData} source="images" accept="image/*">
                  <ImageField source="image" title="title"/>
              </OrderImageInput>)
            }
          }
        </FormDataConsumer>

        <RichTextInput style={{whiteSpace: 'pre-wrap'}} label='내용' source="content" validate={[required, minLength(1), maxLength(3000)]}/>
        <DateTimeInput label='공지일' source="createdAt" validate={required} defaultValue={new Date()}/>
      </SimpleForm>
    </Edit>
    )
}