import BookIcon from '@material-ui/icons/Book'
import DesignSourceCreate from './DesignSourceCreate'
import DesignSourceEdit from './DesignSourceEdit'
import DesignSourceList from './DesignSourceList'
import DesignSourceShow from './DesignSourceShow'

export default {
  list: DesignSourceList,
  create: DesignSourceCreate,
  edit: DesignSourceEdit,
  show: DesignSourceShow,
  icon: BookIcon
}
