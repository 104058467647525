const methods = [
  {id: 'card', name: '카드'},
  {id: 'vbank', name: '가상계좌'}
]

const status = [
  {id: 'ready', name: '가상계좌 발급'},
  {id: 'paid', name: '결제완료'},
  {id: 'sent', name: '배송출발'},
  {id: 'delivered', name: '배송완료'},
  {id: 'order', name: '발주'},
  {id: 'cancelled', name: '주문취소'},
  {id: 'exchange', name: '교환'},
  {id: 'refund', name: '환불'}
]

const sellerId = [
  {id: '1', name: '(주)디팡'},
  {id: '2', name: 'SY&Sons COMPANY'},
  {id: '3', name: 'Barrrk'},
  {id: '4', name: 'PETP BASIC'},
  {id: '5', name: '천군맘마'},
  {id: '6', name: '아인솝 이벤트용'},
  {id: '7', name: '애니스워터 이벤트용'},
  {id: '8', name: '비엔디 이벤트용'},
  {id: '9', name: '큰개닷컴 이벤트용'},
  {id: '10', name: '국개대표'},
  {id: '11', name: '카미 이벤트용'},
  {id: '12', name: '홈앤미 이벤트용'},
  {id: '13', name: '국개대표 쿠폰'},
  {id: '15', name: '펫피 테스트'},
  {id: '16', name: '송송상점'},
  {id: '17', name: '코지힙'},
  {id: '18', name: '리리펫'},
  {id: '19', name: '루루앤미미'},
  {id: '20', name: '엔돌펫'},
  {id: '21', name: '마리스'},
  {id: '22', name: '고리의하루'},
  {id: '23', name: '호지'},
  {id: '24', name: '터치프로젝트'},
  {id: '25', name: '봉주르독'},
  {id: '26', name: '닥터직스'},
  {id: '27', name: '하성전자'},
  {id: '28', name: '오간식'},
  {id: '29', name: '해운대 영무파라드'},
  {id: '30', name: '블랑시오'},
  {id: '31', name: '플럼피'},
  {id: '32', name: 'nunas'},
  {id: '33', name: '엘레강스펫'},
  {id: '34', name: '(주)스위피'},
  {id: '35', name: '앙쥬'},
  {id: '36', name: '훈토스'},
  {id: '37', name: '아아랜드'},
  {id: '38', name: '스코캇'},
  {id: '39', name: '프라나'},
  {id: '40', name: '홈앤미'},
  {id: '41', name: '지니펫'},
  {id: '42', name: '육미육가'},
  {id: '43', name: '어니스트밀'},
  {id: '44', name: '페슬러'},
  {id: '45', name: '두카메디'},
  {id: '46', name: '리토가토'},
  {id: '53', name: '펫피이벤트'},
  {id: '54', name: '독키호테(주식회사 맥시브)'},
  {id: '55', name: '쭈쭈쟁이'}
]

const couriers = [
  {id: '04', name: 'CJ대한통운'},
  {id: '06', name: '로젠택배'},
  {id: '08', name: '롯데택배'},
  {id: '01', name: '우체국택배'},
  {id: '05', name: '한진택배'},
  {id: '22', name: '대신택배'},
  {id: '23', name: '경동택배'},
  {id: '24', name: 'CVSnet 편의점택배'},
  {id: '30', name: 'KGL네트웍스'},
  {id: '32', name: '합동택배'},
  {id: '46', name: 'CU편의점택배'},
  {id: '60', name: '현대택배'},
  {id: '62', name: 'KG옐로우캡'},
  {id: '68', name: '포스트박스(GS25)편의점 택배'},
  {id: '64', name: '용마로지스'},
  {id: '66', name: '동부택배'},
  {id: '11', name: '일양로지스'},
  {id: '40', name: '굿투럭'},
  {id: '43', name: '애니트랙'},
  {id: '44', name: 'SLX'},
  {id: '45', name: '호남택배'},
  {id: '52', name: '세방'},
  {id: '53', name: '농협택배'},
  {id: '54', name: '홈픽택배'},
  {id: '56', name: 'KGB택배'},
  {id: '16', name: '한의사랑택배'},
  {id: '17', name: '천일택배'},
  {id: '18', name: '건영택배'},
  {id: '20', name: '한덱스'},
  {id: '58', name: '하이택배'},
  {id: '94', name: '오늘의픽업'}
]

export default {
  methods,
  status,
  sellerId,
  couriers
}
