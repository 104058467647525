import React from 'react'
import {
  DateInput,
  DisabledInput,
  Edit,
  minValue,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import {required} from "../../validators"

export default (props) => (
    <Edit {...props} actions={<EditActions/>}>
      <SimpleForm toolbar={<EditToolbar/>}>
        <DisabledInput source='id'/>
        <ReferenceInput label="회사" source="sellerId" reference="sellers" validate={[required,]} perPage={150}>
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <TextInput source="title" validate={[required]}/>
        <NumberInput source="point" validate={[required, minValue(0)]}/>
        <DateInput source="startDate" locales='ko' validate={[required]}/>
        <DateInput source="endDate" locales='ko' validate={[required]}/>
      </SimpleForm>
    </Edit>
)
