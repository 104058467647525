import React, {useState, useEffect} from 'react'
import {
  TextField,
  BooleanInput,
  Edit,
  DateTimeInput,
  FormDataConsumer,
  ImageField,
  LongTextInput,
  maxLength,
  SelectInput,
  SimpleForm,
  ImageInput,
  ArrayInput,
  TextInput,
  NumberInput,
  minValue,
  maxValue,
  ReferenceInput,
  SimpleFormIterator
} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import OrderImageInput from '../../custom/common/DragOrderImage/OrderImageInput'
import EditToolbar from '../../custom/common/EditToolbar'
import {dataProvider} from '../../App'

export default (props) => {
  const [data] = useState([])
  const [imageData, setImageData] = useState([])
  return (
    <Edit {...props} actions={<EditActions />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <LongTextInput source="title" defaultValue={''} validate={[required, maxLength(150)]} />
        <LongTextInput source="description" defaultValue={''} validate={[required]} />
        <ReferenceInput
          label="체험단 광고주"
          source="seedCampaignAdvertiserId"
          reference="seedCampaignAdvertiser"
          validate={required}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ImageInput source="titleImage" accept="image/*" validate={required}>
          <ImageField source="image" title="title" />
        </ImageInput>
        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (imageData.length > 0) formData.detailImages = imageData
            if (data.length > 0) formData.detailImages = data
            return (
              <>
                <OrderImageInput
                  label="상세 이미지"
                  placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                  multiple
                  setState={setImageData}
                  source="detailImages"
                  accept="image/*"
                  validate={required}
                >
                  <ImageField source="image" title="title" />
                </OrderImageInput>
                <SelectInput
                  source="channel"
                  label="채널"
                  choices={[
                    {id: 'blog', name: '블로그'},
                    {id: 'instagram', name: '인스타그램'},
                    {id: 'youtube', name: '유튜브'},
                    {id: 'purchaseReview', name: '구매평'}
                  ]}
                  validate={required}
                />
                {formData && formData.channel === 'blog' ? (
                  <ArrayInput source="keyword" label="채널별 필요 정보" validate={required}>
                    <SimpleFormIterator label="채널별 필요 정보">
                      <LongTextInput
                        label="검색 키워드 [콤마(,)로 각 키워드 구분, 최대 10개 가능]"
                        source="keywordSearching"
                        validate={[maxValue(500)]}
                      />
                      <TextField label="현재 어드민 버전 문제로 '추가'가 계속 되고 있습니다. 시스템상으로는 1개까지만 적용이되니 그 이후 '추가'는 적용되지않는 점 참고부탁드립니다" />
                    </SimpleFormIterator>
                  </ArrayInput>
                ) : (
                  <div></div>
                )}
                {formData && formData.channel === 'instagram' ? (
                  <ArrayInput source="keyword" label="채널별 필요 정보" validate={required}>
                    <SimpleFormIterator>
                      <TextInput source="keywordHashtag" label="해시태그" validate={[required]} />
                      <TextField label="현재 어드민 버전 문제로 '추가'가 계속 되고 있습니다. 시스템상으로는 1개까지만 적용이되니 그 이후 '추가'는 적용되지않는 점 참고부탁드립니다" />
                    </SimpleFormIterator>
                  </ArrayInput>
                ) : (
                  <div></div>
                )}
                {formData && formData.channel === 'youtube' ? (
                  <ArrayInput source="keyword" label="채널별 필요 정보" validate={required}>
                    <SimpleFormIterator>
                      <TextInput source="keywordLink" label="링크" validate={[required]} />
                      <br />
                      <TextInput source="keywordTag" label="태그" validate={[required]} />
                      <br />
                      <LongTextInput
                        label="검색 키워드 [콤마(,)로 각 키워드 구분, 최대 10개 가능]"
                        source="keywordSearching"
                        validate={[maxValue(500), required]}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                ) : (
                  <div></div>
                )}
              </>
            )
          }}
        </FormDataConsumer>
        <br />
        <FormDataConsumer>
          {({formData, ...rest}) => {
            const [titleImage, setTitleImage] = useState(formData.announcement?.image || '')
            // formData.channel 값 변경 시 실행되는 Effect 람다
            useEffect(() => {
              const fetchData = async () => {
                const channels = {
                  instagram: 1,
                  blog: 2,
                  youtube: 3,
                  purchaseReview: 4
                }
                const channelId = channels[formData.channel] || 1
                const {data} = await dataProvider('GET_ONE', 'designSource', {id: channelId})
                setTitleImage(data.titleImage.image)
                formData.announcement.image = data.titleImage.image
                // Update the announcement object in state
                // setAnnouncement((prevAnnouncement) => ({
                //   ...prevAnnouncement,
                //   image: data.titleImage.image
                // }))
              }
              fetchData()
            }, [formData.channel, formData.announcement.image])
            return (
              <>
                <LongTextInput
                  label="채널별 팝업 공지"
                  source="announcement.image"
                  validate={[required]}
                  defaultValue={formData.announcement?.image}
                  value={titleImage}
                />
              </>
            )
          }}
        </FormDataConsumer>
        <TextField label="현재 채널별로 팝업 공지는 고정 적용해놨습니다. 변경을 원하시면 개발팀에 문의부탁드립니다" />
        <NumberInput source="recruitmentCount" validate={[required, minValue(1)]} />
        <LongTextInput source="provision" defaultValue={''} />
        <LongTextInput source="mission" defaultValue={''} />
        <LongTextInput source="additionalGuide" defaultValue={''} />
        <br />
        <BooleanInput source="pick" validate={[required]} defaultValue={false} />
        <TextField label="* 활성화 시 체험단 메인 화면에서 '주목할만한 체험단' 영역에 표출됩니다." />
        <BooleanInput source="openPlanned" validate={[required]} defaultValue={true} />
        <TextField label="* 비활성화 시 체험단 메인 화면에서 '오픈 예정 체험단' 영역에 미표출됩니다." />

        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (formData.designSourceId) {
              return (
                <>
                  <BooleanInput
                    source="isDesignSource"
                    label="체험단 상세페이지에서 캡션 이미지를 추가하시겠습니까?"
                    defaultValue={true}
                    validate={[required]}
                  />
                  <ReferenceInput
                    label="캡션 이미지"
                    source="designSourceId"
                    reference="designSource"
                    filter={{detailCategory: 'additionalTitleImage'}}
                    perPage={200}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </>
              )
            } else if (!formData.isDesignSource && formData.designSourceId) {
              return (
                <>
                  <BooleanInput
                    source="isDesignSource"
                    label="체험단 상세페이지에서 캡션 이미지를 추가하시겠습니까?"
                    defaultValue={false}
                    validate={[required]}
                  />
                </>
              )
            } else if (formData.isDesignSource) {
              return (
                <>
                  <BooleanInput
                    source="isDesignSource"
                    label="체험단 상세페이지에서 캡션 이미지를 추가하시겠습니까?"
                    defaultValue={true}
                    validate={[required]}
                  />
                  <ReferenceInput
                    label="캡션 이미지"
                    source="designSourceId"
                    reference="designSource"
                    filter={{detailCategory: 'additionalTitleImage'}}
                    perPage={200}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </>
              )
            } else {
              return (
                <>
                  <BooleanInput
                    source="isDesignSource"
                    label="체험단 상세페이지에서 캡션 이미지를 추가하시겠습니까?"
                    defaultValue={false}
                    validate={[required]}
                  />
                </>
              )
            }
          }}
        </FormDataConsumer>
        <LongTextInput source="attentionPoint" defaultValue={''} />
        <TextField label="* 유저가 체험단을 신청할 때 표출되는 유의사항 내용입니다." />
        <br />
        <DateTimeInput source="recruitmentStartAt" locales="ko" validate={[required]} defaultValue={new Date()} />
        <DateTimeInput source="recruitmentEndAt" locales="ko" validate={[required]} defaultValue={new Date()} />
        <DateTimeInput source="announcedAt" locales="ko" validate={[required]} defaultValue={new Date()} />
        <DateTimeInput source="registrationEndAt" locales="ko" validate={[required]} defaultValue={new Date()} />
      </SimpleForm>
    </Edit>
  )
}
