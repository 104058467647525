import React from 'react'
import {DateField, SelectField, Show, SimpleShowLayout, TextField, translate, BooleanField} from 'react-admin'
import ImageClickField from '../../custom/common/ImageClickField'
import ManagerShowActions from '../../custom/common/ManagerShowActions'

class BannerShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ManagerShowActions />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <ImageClickField label="이미지" addLabel={true} source="imagePath" />
          {/*배너 위치*/}
          <SelectField
            source="target"
            choices={[
              {id: 'landing', name: '❌온 보딩❌'},
              {id: 'talk', name: '❌게시판 탭❌'},
              {id: 'community', name: '❌커뮤니티 배너❌'},
              {id: 'product', name: '❌쇼핑 > 서브배너❌'}, //쇼핑 중간 미디움 배너
              {id: 'product1', name: '❌쇼핑 > 메인배너❌'}, //쇼핑 상단 라지 배너
              {id: 'walk', name: '❌산책 띠배너❌'},
              {id: 'home', name: '홈 > 중간배너'},
              {id: 'communityList', name: '커뮤니티 리스트'},
              {id: 'communityDetail', name: '커뮤니티 상세'},
              {id: 'pointExchangeMain', name: '포인트 교환소 메인'},
              {id: 'shoppingMain', name: '쇼핑 > 상단 메인배너'},
              {id: 'shoppingMiddle', name: '쇼핑 > 중간 서브배너'},
              {id: 'seedCampaign', name: '체험단'},
              {id: 'myPage', name: '마이 페이지'},
              {id: 'walkReady', name: '산책 시작 전'},
              {id: 'walkCalendar', name: '산책 일지'},
              {id: 'onBoarding', name: '앱 시작 화면'}
            ]}
          />
          {/*랜딩 페이지*/}
          <SelectField
            source="type"
            choices={[
              {id: 'events', name: '이벤트'},
              {id: 'notice', name: '공지사항'},
              {id: 'exhibition', name: '기획전'},
              {id: 'community', name: '커뮤니티 상세페이지'},
              {id: 'url', name: '링크'},
              {id: 'image', name: '이미지'},
              {id: 'walk', name: '산책'},
              {id: 'myPage', name: '마이 페이지'},
              {id: 'seedCampaignMain', name: '체험단 메인'},
              {id: 'postingCommunity', name: '커뮤니티 게시글 상세'},
              {id: 'postingNotice', name: '공지사항 게시글 상세'},
              {id: 'postingEvent', name: '이벤트 게시글 상세'},
              {id: 'shoppingExhibition', name: '쇼핑 기획전'},
              {id: 'gmarket', name: 'G마켓'}
            ]}
          />
          {/*해당 ID가 어느 DB에 ID인지 판별이 현재 버전에서는 안됨*/}
          <TextField source="content" />
          <TextField source="adUrl" />
          <TextField source="position" />
          <TextField source="order" />
          <BooleanField source="onOff" />
          <TextField source="userClick" />
          <BooleanField source="isAd" />
          <BooleanField source="isOfferwall" />
          <TextField source="rewardPoint" />
          <DateField source="startDate" locales="ko" showTime sortable={false} />
          <DateField source="endDate" locales="ko" showTime sortable={false} />
          <DateField source="createdAt" />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(BannerShow)
