import BookIcon from '@material-ui/icons/Book'
import SeedCampaignCreate from './SeedCampaignCreate'
import SeedCampaignEdit from './SeedCampaignEdit'
import SeedCampaignShow from './SeedCampaignShow'
import SeedCampaignList from './SeedCampaignList'

export default {
  create: SeedCampaignCreate,
  edit: SeedCampaignEdit,
  list: SeedCampaignList,
  show: SeedCampaignShow,
  icon: BookIcon
}
