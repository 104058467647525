import React from 'react'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {crudDelete, crudGetOne, translate} from 'ra-core'
import {Button as AdminButton, UPDATE} from 'react-admin'
import Button from '@material-ui/core/Button';
import ContentInfo from '@material-ui/icons/Info'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import {dataProvider} from '../../App'
import {connect} from 'react-redux'

const styles = theme => ({
  dialog: {
    width: '80%',
  },
  button: {}
});

class OrderInfoButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isOpen: false, receiverName: null, receiverTel: null,
      address: null, zipCode: null, memo: null}
    this.handleClick = this.handleClick.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.updateReview = this.updateOrder.bind(this)
  }

  async updateOrder() {
    const {dispatchCrudGetOne, basePath, record} = this.props
    const {receiverName, receiverTel, address, zipCode, memo = ''} = this.state
    await dataProvider(UPDATE, 'orders20', {
      id: record.id,
      data: {receiverName, receiverTel, address, zipCode, memo}
    })
    dispatchCrudGetOne('orders20', record.id, basePath)
  }

  handleClick() {
    const {receiverName, receiverTel, address, zipCode, memo} = this.props.record
    this.setState( {isOpen: true, receiverName, receiverTel, address, zipCode, memo})
  }

  handleCancel() {
    this.setState({isOpen: false})
  }

  async handleDone(e) {
    e.stopPropagation()
    await this.updateOrder()
    this.setState({isOpen: false})
  }

  handleChange(e) {
    const {name, value} = e.target
    this.setState({[name]: value})
  }

  render() {
    const {isOpen, receiverName, receiverTel, address, zipCode, memo} = this.state
    const {classes, translate, record,} = this.props

    return record.status !== 'cancelled' ?
      (<div>
        <AdminButton label="정보" onClick={this.handleClick}>
          <ContentInfo/>
        </AdminButton>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogTitle id="confirmation-dialog-title">정보 변경</DialogTitle>
          <DialogContent>
            {record.status !== 'delivered' &&
            <div><TextField label={translate('resources.orders20.fields.receiverName')}
                            style={{width: '100%'}}
                            margin="normal"
                            name='receiverName'
                            onChange={this.handleChange}
                            value={receiverName}/>
              <TextField label={translate('resources.orders20.fields.receiverTel')}
                         style={{width: '100%'}}
                         margin="normal"
                         name='receiverTel'
                         onChange={this.handleChange}
                         value={receiverTel}/>
              <TextField label={translate('resources.orders20.fields.receiverAddress')}
                         style={{width: '100%'}}
                         margin="normal"
                         name='address'
                         onChange={this.handleChange}
                         value={address}/>
              <TextField label={translate('resources.orders20.fields.zipCode')}
                         style={{width: '100%'}}
                         margin="normal"
                         name='zipCode'
                         onChange={this.handleChange}
                         value={zipCode}/>
            </div>}
            <TextField label={translate('resources.orders20.fields.memo')}
                       style={{width: '100%'}}
                       margin="normal"
                       name='memo'
                       onChange={this.handleChange}
                       value={memo}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              취소
            </Button>
            <Button onClick={this.handleDone} color="primary">
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </div>) : ''
  }
}

export default compose(
  connect(
    null,
    {
      dispatchCrudGetOne: crudGetOne,
      dispatchCrudDelete: crudDelete
    }
  ),
  translate,
  withStyles(styles)
)(OrderInfoButton)
