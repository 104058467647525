import React from 'react'
import {
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  ImageInput,
  ImageField
} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="title" validate={required} />
      <TextInput source="subTitle" validate={required} />

      <SelectInput
        source="type"
        choices={[
          {id: 'basic', name: '기본'},
          {id: 'doublePoint', name: '포인트 두배'}
        ]}
        validate={required}
      />
      <SelectInput
        source="showType"
        choices={[
          {id: 'slide', name: '가로'},
          {id: 'grid', name: '세로'}
        ]}
        validate={required}
      />
      <NumberInput source="order" validate={required} />

      <ImageInput source="imagePath" accept="image/*">
        <ImageField source="image" title="title" />
      </ImageInput>

      <SelectInput
        source="handleType"
        label="핸들타입"
        defaultValue="none"
        choices={[
          {id: 'none', name: '없음'},
          {id: 'url', name: 'URL'}
        ]}
      />
      <FormDataConsumer>
        {({formData, ...rest}) => {
          if (formData.handleType === 'url') {
            return <TextInput source="content" defaultValue="" />
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)
