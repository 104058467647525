import React from 'react'
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  translate
} from 'react-admin'
import ImageClickField from '../../custom/common/ImageClickField'
import ManagerShowActions from '../../custom/common/ManagerShowActions'

class PointExchangeShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ManagerShowActions />}>
        <SimpleShowLayout>
          <ReferenceField label="카테고리 종류" source="categoryId" reference="categories">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="업체 종류" source="sellerId" reference="sellers" linkType={'show'}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="name" />

          <TextField label="검색키워드[콤마(,)로 각 키워드 구분, 최대 10개까지 가능]" source="keyWords" />

          <ImageClickField label="대표 이미지" addLabel={true} source="titleImagePath" />

          <ImageClickField label="이미지" addLabel={true} source="images" src="src" />

          <ImageClickField label="상세 이미지" addLabel={true} source="detailImages" src="src" />

          <ImageClickField label="배송 정책 이미지" addLabel={true} source="returnPolicy" src="src" />

          <ImageClickField label="교환/환불 정책 이미지" addLabel={true} source="exchangePolicy" src="src" />
          <hr style={{width: '100%', borderTop: '1px solid #ccc', margin: '20px 0'}} />

          <NumberField source="price" />

          <BooleanField source="isTodayFee" />
          <ImageClickField source="todayFeeImage" addLabel={true} />

          <NumberField source="deliveryFee" />
          <NumberField source="extraFee" />
          <BooleanField source="packing" />
          <hr style={{width: '100%', borderTop: '1px solid #ccc', margin: '20px 0'}} />

          <ArrayField source="options">
            <Datagrid>
              <TextField label={'이름'} source="name" />
              <NumberField label={'옵션 추가 금액'} source="price" />
              <NumberField label={'수량'} source="quantity" />
            </Datagrid>
          </ArrayField>

          <BooleanField label={'추천 상품 여부'} source="recommendation" />

          <BooleanField source="isBuyLimit" label="구매 제한 여부" />
          <NumberField source="buyLimit" label="구매 제한 수량(1~100)" />
          <hr style={{width: '100%', borderTop: '1px solid #ccc', margin: '20px 0'}} />
          <NumberField source="usePoint" />
          <BooleanField source="onSale" />

          <DateField source="startDate" locales="ko" />
          <DateField source="endDate" locales="ko" />

          <NumberField source="hotDealPrice" />

          <DateField source="createdAt" locales="ko" showTime />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(PointExchangeShow)
