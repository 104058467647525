import React from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button} from 'react-admin'
import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const UserListExcelExportButton = (props) => {
  const excelExport = async () => {
    const dataArr = [
      [
        'ID',
        '닉네임',
        '포인트',
        '소멸예정 포인트',
        '번호',
        '이메일',
        '마케팅 동의 여부',
        '추천인 ID',
        '추천인 코드 제목',
        '산책수',
        '포스팅수',
        '댓글 수',
        '좋아요 수',
        '펫친 수',
        '구매횟수',
        '구매가격',
        '가입일'
      ]
    ]

    const {
      data: {data}
    } = await dataProvider('EXCEL', 'users')
    for (let i = 0; i < data.length; i++) {
      let redeemData
      if (data[i].redeemCodeId) {
        redeemData = await dataProvider('GET_CODE', 'redeems', {id: data[i].redeemCodeId})
        data[i].redeemCode = redeemData.data.title
      }
    }
    data.forEach((user, index) => {
      const accounts = user.accounts.email
      dataArr.push([
        user.id,
        user.name,
        user.point,
        user.expirePoint,
        user.phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
        accounts,
        user.agreeMarketing ? 'O' : 'X',
        user.referrerId,
        user.redeemCode,
        user.walkCount,
        user.postCount,
        user.commentCount,
        user.likeCount,
        user.friendsCount,
        user.purchaseCount,
        user.purchasePrice,
        new Date(user.createdAt).toLocaleString()
      ])
    })
    excelDownload(dataArr, '유저 리스트 목록')
  }
  return (
    <Button label="List Excel" onClick={excelExport}>
      <ImportExportIcon />
    </Button>
  )
}

export default UserListExcelExportButton
