import BookIcon from '@material-ui/icons/Book'
import SellerCreate from './SellerCreate'
import SellerEdit from './SellerEdit'
import SellerShow from './SellerShow'
import SellerList from './SellerList'

export default {
  list: SellerList,
  create: SellerCreate,
  edit: SellerEdit,
  show: SellerShow,
  icon: BookIcon,
}
