import BookIcon from '@material-ui/icons/Book'
import SeedCampaignAdvertiserCreate from './SeedCampaignAdvertiserCreate'
import SeedCampaignAdvertiserList from './SeedCampaignAdvertiserList'
// import SeedCampaignAdvertiserEdit from './SeedCampaignAdvertiserEdit'
// import SeedCampaignAdvertiserShow from './SeedCampaignAdvertiserShow'

export default {
  create: SeedCampaignAdvertiserCreate,
  list: SeedCampaignAdvertiserList,
  // edit: SeedCampaignAdvertiserEdit,
  // show: SeedCampaignAdvertiserShow,
  icon: BookIcon
}
