import React from 'react'

import {DateField, ReferenceField, SelectField, Show, SimpleShowLayout, TextField, translate,} from 'react-admin'
import DeleteActions from "../../custom/common/DeleteActions"

class ReportShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
        <Show {...props} actions={<DeleteActions/>}>
          <SimpleShowLayout>
            <ReferenceField label="신고된 글" source="reviewId" reference="productReviews" linkType='show'>
              <TextField source="description" />
            </ReferenceField>

            <ReferenceField label="사용자 이름" source="userId" reference="users" linkType='show'>
              <TextField source="name" />
            </ReferenceField>

            <TextField source="title"/>
            <TextField source="description"/>
            <SelectField source="category" choices={[
              {id: 'ad', name: '광고'},
              {id: 'spam', name: '스팸'},
              {id: 'inappropriateContent', name: '욕설/비방/혐오'},
            ]} sortable={false}/>
            <DateField source="createdAt" locales='ko' showTime/>
          </SimpleShowLayout>
        </Show>
    )
  }
}

export default translate(ReportShow)
