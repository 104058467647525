import React, {useState} from 'react'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import {Button, Loading} from 'react-admin'
import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const MissionSubmitListExcelExportButton = ({record}) => {
  const [loading, setLoading] = useState(false)

  const excelExport = async () => {
    try {
      // 여기에 엑셀 정보를 차곡차곡 쌓음, 첫 array 는 엑셀의 제목
      setLoading(true)
      const excelData = [
        [
          '미션제출 ID',
          '유저 ID',
          '유저 닉네임',
          '유저 이름',
          '유저 휴대폰',
          '배송 정보 - 주소 / 상세',
          '체험단 타이틀',
          '신청하기에 작성한 URL',
          '신청하기 여부',
          '확인하기 여부',
          '미션 제출 상태',
          '미션 제출 URL',
          '미션 제출 이미지'
        ]
      ]
      // 최초 1회는 그냥 가져옴 (total 값이 있어야 반복 가능)
      const getMissionSubmitData = await dataProvider('MISSION_SUBMIT', 'seedCampaign', {id: record.id})
      // 가져온 데이터를 쌓음
      const rows = getMissionSubmitData.data
      for (let i = 0; i < rows.length; i++) {
        const {
          id,
          userId,
          name,
          userName,
          userPhone,
          userAddress,
          userAddressDetail,
          title,
          url,
          isSelected,
          isConfirmed,
          status,
          missionUrl,
          missionImages
        } = rows[i]
        excelData.push([
          id,
          userId,
          name,
          userName,
          userPhone,
          userAddress + '  ' + userAddressDetail,
          title,
          url,
          isSelected,
          isConfirmed,
          status,
          missionUrl,
          missionImages
        ])
      }
      setLoading(false)
      excelDownload(excelData, `${record.id}_완료_${record.title}.xlsx`)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Button label="완료 내역 리스트 다운로드" onClick={excelExport}>
        {loading ? (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%'
            }}
          >
            <Loading style={{position: 'relative', zIndex: '1000'}} className="target" />
          </div>
        ) : (
          <VerticalAlignBottomIcon />
        )}
      </Button>
    </>
  )
}

export default MissionSubmitListExcelExportButton
