import React from 'react'

import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  translate,
    DateField
} from 'react-admin'

import ImageClickField from '../../custom/common/ImageClickField'
import {CardActions, EditButton, ListButton} from 'ra-ui-materialui'
import RichTextField from "ra-ui-materialui/lib/field/RichTextField";

const ShowActions = ({basePath, data}) => {
  return (
    <CardActions>
      <ListButton basePath={basePath}/>
      <EditButton basePath={basePath} record={data}/>
    </CardActions>
  )
}

class SellerNoticeShow extends React.Component {
  render() {
    const {...props} = this.props;

    return (
      <Show {...props} actions={<ShowActions/>}>
        <TabbedShowLayout>
          <Tab label={'기본 정보'}>
            <TextField source="id" label='ID'/>
            <TextField source="title" label='제목'/>
            <ImageClickField label='이미지' addLabel={true}
                             source="images" src="image"/>
            <RichTextField style={{whiteSpace: 'pre-wrap'}} label='내용' source="content"/>
            <DateField style={{whiteSpace: 'pre-wrap'}} label='공지일' source="createdAt" locales='ko' showTime/>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(SellerNoticeShow)
