import React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput
} from 'react-admin'

import EditActions from '../../custom/common/EditActions'
import {required} from "../../validators"
import EditToolbar from "../../custom/common/EditToolbar";

export default (props) => (
    <Edit {...props} actions={<EditActions/>}>
      <SimpleForm toolbar={<EditToolbar/>}>
        <TextInput source="name" validate={required}/>
        <NumberInput source='order' defaultValue={1000} validate={required}/>
      </SimpleForm>
    </Edit>
)
