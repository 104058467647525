import React from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button} from 'react-admin'
import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const ListExcelExportButton = (props) => {
  const excelExport = async () => {
    const dataArr = [['ID', '유저ID', '닉네임', '게시글ID', '댓글 내용', '작성일']]
    const {
      data: {data}
    } = await dataProvider('EXCEL', 'noticeComments')

    data.forEach((nc, index) => {
      try {
        dataArr.push([nc.id, nc.userId, nc.name, nc.noticeId, nc.content, new Date(nc.createdAt).toLocaleString()])
      } catch (e) {
        console.error(e)
      }
    })
    excelDownload(dataArr, '게시글 리스트 목록')
  }

  return (
    <Button label="전체 리스트 다운로드" onClick={excelExport}>
      <ImportExportIcon />
    </Button>
  )
}

export default ListExcelExportButton
