import BookIcon from '@material-ui/icons/Book'
import SeedCampaignInquiryShow from './SeedCampaignInquiryShow'
import SeedCampaignInquiryEdit from './SeedCampaignInquiryEdit'
import SeedCampaignInquiryList from './SeedCampaignInquiryList'

export default {
  edit: SeedCampaignInquiryEdit,
  list: SeedCampaignInquiryList,
  show: SeedCampaignInquiryShow,
  icon: BookIcon
}
