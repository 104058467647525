import React from 'react'

import {DateField, SelectField, Show, SimpleShowLayout, TextField, translate,} from 'react-admin'
import AdminShowActions from "./AdminShowActions"

class AdminShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
        <Show {...props} actions={<AdminShowActions/>}>
          <SimpleShowLayout>

            <TextField source="id"/>
            <SelectField source="auth" choices={[
              {id: 'admin', name: '관리자'},
              {id: 'manager', name: '매니저'},
              {id: 'master', name: '마스터'},
            ]} sortable={false}/>
            <TextField source="name"/>
            <DateField source="createdAt" locales='ko' showTime/>
          </SimpleShowLayout>

        </Show>
    )
  }
}

export default translate(AdminShow)
