import BookIcon from '@material-ui/icons/Book'
import BannerCreate from './BannerCreate'
import BannerEdit from './BannerEdit'
import BannerShow from './BannerShow'
import BannerList from './BannerList'

export default {
  list: BannerList,
  create: BannerCreate,
  edit: BannerEdit,
  show: BannerShow,
  icon: BookIcon
}
