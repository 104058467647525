import BookIcon from '@material-ui/icons/Book'
import EventCreate from './EventCreate'
import EventEdit from './EventEdit'
import EventShow from './EventShow'
import EventList from './EventList'

export default {
  list: EventList,
  create: EventCreate,
  edit: EventEdit,
  show: EventShow,
  icon: BookIcon
}
