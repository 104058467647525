import React from 'react'

import {BooleanField, Edit, SimpleForm} from 'react-admin'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <BooleanField source="agreeMarketing" required={true} />
    </SimpleForm>
  </Edit>
)
