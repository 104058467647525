import BookIcon from '@material-ui/icons/Book'
import PointExchangeCreate from './PointExchangeCreate'
import PointExchangeEdit from './PointExchangeEdit'
import PointExchangeShow from './PointExchangeShow'
import PointExchangeList from './PointExchangeList'

export default {
  create: PointExchangeCreate,
  edit: PointExchangeEdit,
  list: PointExchangeList,
  show: PointExchangeShow,
  icon: BookIcon
}
