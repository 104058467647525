import React from 'react'
import ManagerShowActions from '../../custom/common/ManagerShowActions'
import {BooleanField, DateField, ReferenceField, Show, SimpleShowLayout, TextField, translate} from 'react-admin'
import ImageClickField from '../../custom/common/ImageClickField'

class OfferwallShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ManagerShowActions />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="title" />
          <TextField source="subTitle" />
          <ImageClickField addLabel={true} source="titleImagePath" src="image" />
          <TextField source="type" sortable={false} />
          <TextField source="rewardType" sortable={false} />
          <ReferenceField
            label="클릭 보너스 이미지"
            source="rewardImage"
            reference="designSource"
            linkType={'show'}
            perPage={100}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="rewardValue" sortable={false} />
          <TextField source="landingType" sortable={false} />
          <TextField source="landingValue" sortable={false} />
          <BooleanField source="onOff" sortable={true} />
          <BooleanField source="isAd" sortable={true} />
          <TextField source="userClick" sortable={false} />
          <DateField source="startDate" locales="ko" showTime sortable={false} />
          <DateField source="endDate" locales="ko" showTime sortable={false} />
          <DateField source="createdAt" locales="ko" showTime sortable={false} />
        </SimpleShowLayout>
      </Show>
    )
  }
}
export default translate(OfferwallShow)
