import React from 'react'

import {DateField, ReferenceField, Show, SimpleShowLayout, TextField, translate} from 'react-admin'

import {CardActions, DeleteButton} from "ra-ui-materialui";

const ShowActions = ({basePath, data, resource}) => {
  return (
      <CardActions>
          <DeleteButton basePath={basePath} resource={resource} record={data}/>
      </CardActions>
  )
}

class DeletedUserShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
        <Show {...props} actions={<ShowActions />}>
          <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField source="userId" reference="users" allowEmpty linkType={'show'}>
              <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="talkId" reference="talks" allowEmpty linkType={'show'}>
              <TextField source="title"/>
            </ReferenceField>
            <TextField source="content"/>
            <DateField source="createdAt" locales='ko' showTime/>
          </SimpleShowLayout>
        </Show>
    )
  }
}

export default translate(DeletedUserShow)
