import React from 'react'

import {
  ArrayField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  translate
} from 'react-admin'
import {CardActions, ListButton} from 'ra-ui-materialui'

const Actions = ({basePath, data}) => (
  <CardActions>
    <ListButton basePath={basePath}/>
  </CardActions>
)


class PointShow extends React.Component {
  render() {
    const {...props} = this.props
    const CustomReferenceField = (props) => {
      return (
        <>
          {props.record.info && props.record.info.orderId &&
          <>
            <span> 주문: </span>
            <ReferenceField {...props} source="info.orderId" reference="orders" linkType={'show'}>
              <TextField source="id"/>
            </ReferenceField>
          </>
          }
          {props.record.info && props.record.info.walkId &&
          <p> 산책 </p>
          }
        </>)
    }
    return (
      <Show {...props} actions={<Actions/>}>
        <TabbedShowLayout>
          <Tab label='기본 정보'>
            <TextField source="id" sortable={true}/>
            <ReferenceField label="사용자 이름" source="userId" reference="users" linkType='show'>
              <TextField source="name"/>
            </ReferenceField>
            <SelectField source="type" choices={[
              {id: 'custom', name: '지정'},
              {id: 'cancelOrder', name: '주문 취소'},
              {id: 'walk', name: '산책'},
              {id: 'purchase', name: '구매'},
            ]}/>
            <NumberField source="point" sortable={false}/>
            <NumberField source="remainPoint" sortable={false}/>
            <TextField source="description" sortable={false}/>
            <DateField source="expireAt" locales='ko' showTime sortable={true}/>
            <DateField source="createdAt" locales='ko' showTime sortable={true}/>
          </Tab>
          <Tab label='사용 내역'>
            <ArrayField source="log" label='' addLabel={true}>
              <Datagrid>
                <TextField source="id" sortable={false}/>
                <NumberField source="point" sortable={false}/>
                <CustomReferenceField source="info" sortable={false} label={'정보'}/>
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(PointShow)
