import React from 'react'
import {connect} from 'react-redux'
import {getResources, MenuItemLink, translate, DashboardMenuItem} from 'react-admin'
import {withRouter} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import {withStyles} from '@material-ui/core/styles'

const styles = (theme) => ({
  root: {
    width: '100%',
    margin: 0
  },
  heading: {
    margin: 0,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
})
let menus = [
  {
    title: 'DashBoard',
    items: ['dashboard'],
    isDashboard: true
  },
  {
    title: '시스템 관리',
    items: ['admins', 'home', 'designSource', 'gaReports']
  },
  {
    title: '사용자 관리',
    items: ['users', 'deletedUsers', 'messages', 'points', 'breeds']
  },
  {
    title: '커뮤니티 관리',
    items: ['notices', 'noticeComments', 'communityPopular', 'talks', 'comments', 'communityCategory']
  },
  {
    title: '체험단 관리',
    items: [
      'seedCampaign',
      'seedCampaignInquiry',
      'seedCampaignInquiryCategory',
      'seedCampaignBestReview',
      'seedCampaignApply',
      'seedCampaignAdvertiser'
    ]
  },
  {
    title: '이벤트 관리',
    items: ['events', 'eventsComments']
  },
  {
    title: '배너 관리',
    items: ['banners']
  },
  {
    title: '포인트 교환소 관리',
    items: ['pointExchange']
  },
  {
    title: '쇼핑 관리',
    items: [
      'products',
      'categories',
      'productQAs',
      'productReviews',
      'productReviewReports',
      'exhibition',
      'sellers',
      'sellerNotices'
    ]
  },
  {
    title: '주문/정산 관리',
    items: ['orders20', 'balanceAccount']
  },
  {
    title: '신고 관리',
    items: ['ugcReports']
  },
  {
    title: '광고 관리',
    items: ['advertising', 'popups', 'offerwall']
  },
  {
    title: '장소 관리',
    items: ['places', 'facilities', 'services', 'placeBreeds', 'placeKeywords']
  },
  {
    title: '추천인 코드 관리',
    items: ['redeems']
  },
  {
    title: '컨텐츠 관리',
    items: ['knowledge', 'categorySympathy', 'reviews']
  },
  {
    title: '기부 관리',
    items: ['donation', 'donationDestination', 'donationGoods']
  }
]

const Menu = withStyles(styles)(({resources, classes, onMenuClick, logout, translate, location}) => {
  //administrator 에서 유저에게 속한 auth
  const permissions = localStorage.getItem('auth')
  if (permissions === 'manager') {
    if (menus[1].title === '시스템 관리') {
      menus = [...menus.slice(0, 1), ...menus.slice(2, 12)]
    }
  }
  //왼쪽 메뉴 탭에대한 정보들이 표출
  return menus.map((menu, index) => {
    let items = null
    //각 메뉴가 가지고 있는 정보들을 말하는 resources
    if (menu.title === 'DashBoard') {
      return <DashboardMenuItem key={index} primaryText={translate(`ra.page.dashboard`)} onClick={onMenuClick} />
    } else {
      items = menu.items.map((item, index) => {
        const resource = resources.find((resource) => resource.name === item)
        //메뉴에 해당하는 정보들을 표출하기 위한 매핑 작업
        if (resource) {
          return (
            <MenuItemLink
              key={index}
              to={`/${resource.name}`}
              primaryText={translate(`resources.${resource.name}.name`)}
              onClick={onMenuClick}
            />
          )
        }
        return <MenuItemLink disabled key={index} to={`/`} primaryText={item + ' (개발)'} onClick={onMenuClick} />
      })

      let defaultExpanded = false
      if (menu.items.find((item) => location.pathname.indexOf(item) > 0)) defaultExpanded = true
      return (
        <ExpansionPanel key={index} defaultExpanded={defaultExpanded}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{menu.title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{display: 'block'}}>{items}</ExpansionPanelDetails>
        </ExpansionPanel>
      )
    }
  })
})

const mapStateToProps = (state) => ({
  resources: getResources(state)
})

export default withRouter(connect(mapStateToProps)(translate(Menu)))
