import BookIcon from '@material-ui/icons/Book'
import SeedCampaignApplyShow from './SeedCampaignApplyShow'
import SeedCampaignApplyEdit from './SeedCampaignApplyEdit'
import SeedCampaignApplyList from './SeedCampaignApplyList'

export default {
  edit: SeedCampaignApplyEdit,
  list: SeedCampaignApplyList,
  show: SeedCampaignApplyShow,
  icon: BookIcon
}
