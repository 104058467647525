import BookIcon from '@material-ui/icons/Book'
import QaEdit from './QaEdit'
import QaShow from './QaShow'
import QaList from './QaList'

export default {
  list: QaList,
  edit: QaEdit,
  show: QaShow,
  icon: BookIcon,
}
