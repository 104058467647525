import React, {useState} from 'react'
import {
  Create,
  FormDataConsumer,
  ImageField,
  ImageInput, LongTextInput,
  maxLength,
  minLength, SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import OrderImageInput from "../../custom/common/DragOrderImage/OrderImageInput"

export default (props) => {
  const [imageData, setImageData] = useState([])
  return (
      <Create {...props} actions={<CreateActions/>}>
        <SimpleForm
            toolbar={<CreateToolbar/>}>

          <TextInput source="title" validate={[required, minLength(1), maxLength(50)]}/>
          <TextInput source="subTitle" validate={[required, minLength(1), maxLength(100)]}/>
          <LongTextInput source="content" validate={[required]}/>
          <SelectInput source="type" choices={[{id: 'text', name: 'text'}, {id: 'url', name: 'url'}]}
                       validate={required}/>
          <ImageInput source="thumbnailPath" accept="image/*" validate={required}>
            <ImageField source="src" title="title"/>
          </ImageInput>
          <FormDataConsumer>
            {({formData, ...rest}) => {
              if (imageData.length > 0) {
                formData.images = imageData
              }
              return (
                  <OrderImageInput label='이미지' placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>} multiple
                                   setState={setImageData} source="images" accept="image/*"
                                   validate={required}>
                    <ImageField source="image" title="title"/>
                  </OrderImageInput>)
            }
            }
          </FormDataConsumer>

        </SimpleForm>
      </Create>
  )
}
