import React, { useEffect, useState } from 'react';

const GaReportList = () => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const iframePart = () => {
    const iframeWidth = windowSize.width;
    const iframeHeight = windowSize.height;
    const reportUrl = process.env.NODE_ENV === 'production'
        ? 'https://lookerstudio.google.com/embed/reporting/a1553a39-0f92-4cd4-a8a3-2990ee172ee8/page/CX5TD'
        : 'https://lookerstudio.google.com/embed/reporting/503c39a3-d3e6-4964-9f80-be26ec7e42ed/page/CX5TD';

    return {
      __html: `<iframe width="${iframeWidth}" height="${iframeHeight}" src="${reportUrl}" frameborder="0" style="border:0" allowfullscreen></iframe>`
    };
  };

  return (
      <div
          style={{ margin: 'auto', position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}
          dangerouslySetInnerHTML={iframePart()}
      />
  )
}

export default GaReportList
