import React from 'react'
import {DateField, NumberField, Show, SimpleShowLayout, TextField, translate} from 'react-admin'
import ManagerShowActions from "../../custom/common/ManagerShowActions"

class CategoryShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ManagerShowActions/>}>
        <SimpleShowLayout>
          <TextField source="id"/>
          <TextField source="name"/>
          <NumberField source="order"/>
          <DateField source="createdAt" locales='ko' showTime sortable={false}/>
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(CategoryShow)
