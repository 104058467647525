import React from 'react'
import {BooleanField, CardActions, Datagrid, DateField, Filter, List, SearchInput, TextField} from 'react-admin'
import MessagesPush from "./MessagePush";

const Actions = props => {
    return(
      <CardActions>
          <MessagesPush {...props}/>
      </CardActions>
    )
}

const ListFilter = props => {
    return (
        <Filter {...props}>
            <SearchInput label={'발신자'} source='sender' alwaysOn/>
            <SearchInput label={'수신자'} source='receiver' alwaysOn/>
        </Filter>
    )
}

export const MessagesList = (props) => {
    return(
        <List
            {...props}
            perPage={25}
            filters={<ListFilter/>}
            actions={<Actions/>}
        >
            <Datagrid rowClick='show'>
                <TextField source='id'/>
                <TextField source='senderId'/>
                <TextField source='senderName'/>
                <TextField source='userId'/>
                <TextField source='userName'/>
                <TextField source='text'/>
                <BooleanField source='read'/>
                <DateField source='createdAt'/>
            </Datagrid>
        </List>
    )
}
