import BookIcon from '@material-ui/icons/Book'
import HomeCreate from './HomeCreate'
import HomeEdit from './HomeEdit'
import HomeList from './HomeList'
import HomeShow from './HomeShow'

export default {
  list: HomeList,
  create: HomeCreate,
  edit: HomeEdit,
  show: HomeShow,
  icon: BookIcon
}
