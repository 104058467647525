import React from 'react'
import {
  DateField,
  SelectField,
  Show,
  TextField,
  translate,
  ArrayField,
  Datagrid,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  BooleanField,
  FormDataConsumer,
  CardActions,
  EditButton
} from 'react-admin'
import ImageClickField from '../../custom/common/ImageClickField'
import ApplicantStatusButton from './ApplicantStatusButton'
import ApplicantListExcelExportButton from './ApplicantListExcelExportButton'
import MissionSubmitListExcelExportButton from './MissionSubmitListExcelExportButton'

class SeedCampaignShow extends React.Component {
  state = {selectedIds: []}
  onToggleItem = (id) => {
    if (this.state.selectedIds.indexOf(id) === -1) {
      this.setState({selectedIds: [...this.state.selectedIds, id]})
    } else {
      this.setState({selectedIds: this.state.selectedIds.filter((selectedId) => selectedId !== id)})
    }
  }
  handleActionDone = () => {
    this.setState({selectedIds: []})
    window.location.reload(true)
  }
  handleAllSelect = (ids) => {
    this.setState({selectedIds: ids})
  }

  render() {
    const ShowActions = ({basePath, data, ...props}) => {
      const selectedIds = this.state.selectedIds.map((item) => JSON.parse(item).id)
      return (
        <CardActions>
          <EditButton basePath={basePath} record={data} />
          <ApplicantStatusButton seedCampaignData={data} selectedIds={selectedIds} onDone={this.handleActionDone} />
          <ApplicantListExcelExportButton record={data} />
          <MissionSubmitListExcelExportButton record={data} />
        </CardActions>
      )
    }
    const {classes, translate, ...props} = this.props
    // const CustomStatusField = ({record}) => {
    //   if (record.status === 'UNSELECTED') {
    //     return <span style={{textDecoration: 'line-through'}}>{record.status}</span>
    //   }
    //   return <span>{record.status}</span>
    // }
    return (
      <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout>
          <Tab label={'기본 정보'}>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="description" />
            <ImageClickField label="대표 이미지" addLabel={true} source="titleImage" src="image" />
            <ImageClickField label="상세 이미지" addLabel={true} source="detailImages" src="image" />
            <SelectField
              source="channel"
              label="채널"
              choices={[
                {id: 'instagram', name: '인스타그램'},
                {id: 'blog', name: '블로그'},
                {id: 'youtube', name: '유튜브'},
                {id: 'purchaseReview', name: '구매평'}
              ]}
            />

            <FormDataConsumer>
              {({formData, ...rest}) => {
                if (formData.channel === 'instagram') {
                  return (
                    <>
                      <TextField label="해시태그" source="keyword[0].keywordHashtag" {...rest} />
                    </>
                  )
                } else if (formData.channel === 'blog') {
                  return (
                    <>
                      <TextField source="keyword[0].keywordSearching" label="검색 키워드" {...rest} />
                    </>
                  )
                } else if (formData.channel === 'youtube') {
                  return (
                    <>
                      <TextField source="keyword[0].keywordTag" label="태그" {...rest} />
                      <TextField source="keyword[0].keywordLink" label="링크" {...rest} />
                      <TextField source="keyword[0].keywordSearching" label="검색 키워드" {...rest} />
                    </>
                  )
                } else if (formData.channel === 'purchaseReview') {
                  return <TextField source="keyword[0].keywordSearching" label="검색 키워드" {...rest} />
                } else {
                  return null
                }
              }}
            </FormDataConsumer>

            <ReferenceField
              label="캡션 이미지"
              source="designSourceId"
              reference="designSource"
              linkType={'show'}
              perPage={100}
            >
              <TextField source="name" />
            </ReferenceField>
            <ImageClickField label="채널별 팝업 이미지" addLabel={true} source="announcement" src="image" />
            <TextField label="- 채널에 따라 자동으로 팝업 이미지가 띄워집니다." />
            <TextField source="provision" />
            <TextField source="mission" />
            <TextField source="additionalGuide" />
            <TextField source="attentionPoint" />
            <br />
            <DateField source="recruitmentStartAt" locales="ko" showTime sortable={true} />
            <DateField source="recruitmentEndAt" locales="ko" showTime sortable={true} />
            <DateField source="announcedAt" locales="ko" showTime sortable={true} />
            <DateField source="registrationEndAt" locales="ko" showTime sortable={true} />
            <DateField source="createdAt" />
          </Tab>
          <Tab label={'신청 내역'}>
            <ArrayField source="participation" addLabel={false}>
              <Datagrid
                rowClick="show"
                selectedIds={this.state.selectedIds}
                onToggleItem={this.onToggleItem}
                onSelect={this.handleAllSelect}
                hasBulkActions={true}
              >
                <TextField label="index" source="index" sortable={false} />
                <TextField label="유저 ID" source="userId" sortable={false} />
                <ReferenceField label="유저닉네임" source="userId" reference="users" linkType={'show'}>
                  <TextField source="name" />
                </ReferenceField>

                <BooleanField label="신청 여부" source="isApplied" sortable={false} />
                <BooleanField label="선정 여부" source="isSelected" sortable={false} />
                <BooleanField label="확인 여부" source="isConfirmed" sortable={false} />

                <DateField label="신청일" source="createdAt" locales="en" showTime sortable={true} />
                <DateField label="수정일" source="updatedAt" locales="en" showTime sortable={true} />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label={'진행 내역'}>
            <ArrayField source="submitter" addLabel={false}>
              <Datagrid
                rowClick="show"
                selectedIds={this.state.selectedIds}
                onToggleItem={this.onToggleItem}
                onSelect={this.handleAllSelect}
                hasBulkActions={true}
              >
                <TextField label="index" source="index" sortable={false} />
                <TextField label="유저ID" source="userId" sortable={false} />
                <ReferenceField label="유저닉네임" source="userId" reference="users" linkType={'show'}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="상태" source="status" sortable={false} />
                <TextField label="미션제출 URL" source="missionUrl" sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}
export default translate(SeedCampaignShow)
