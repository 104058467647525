import React from "react"

export const TextArrayField = ({data}) => {

  const arrayValues = Object.values(data)
  return (
      <>
        {arrayValues.map((value, index) => <div key={value+index}>
          <li >{value}</li>
          <br/>
        </div>)}
      </>
  )
}