import BookIcon from '@material-ui/icons/Book'
import BreedCreate from './BreedCreate'
import BreedEdit from './BreedEdit'
import BreedShow from './BreedShow'
import BreedList from './BreedList'

export default {
  list: BreedList,
  create: BreedCreate,
  edit: BreedEdit,
  show: BreedShow,
  icon: BookIcon,
}
