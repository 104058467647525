import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  ImageField,
  List,
  NumberField,
  SelectField,
  TextField
} from 'react-admin'
import {required} from '../../validators'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const Actions = ({basePath, props}) => {
  const permissions = localStorage.getItem('auth')
  return <CardActions>{permissions !== 'manager' ? <CreateButton basePath={basePath} /> : null}</CardActions>
}

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} bulkActions={false}>
      <Datagrid rowClick="show">
        <NumberField source="id" sortable={false} />
        <ThumbnailImageField source="titleImage" sortable={false} />
        <TextField source="name" sortable={false} />
        <SelectField
          source="category"
          label="대분류"
          choices={[
            {id: 'seedCampaign', name: '체험단'},
            {id: 'banners', name: '배너'},
            {id: 'popups', name: '팝업'},
            {id: 'offerwall', name: '오퍼월'}
          ]}
          validate={required}
        />
        <SelectField
          source="detailCategory"
          label="소분류"
          choices={[
            {id: 'channelPopupImage', name: '채널별 팝업 공지'},
            {id: 'additionalTitleImage', name: '캡션 이미지'},
            {id: 'offerwallBannerImage', name: '오퍼월 배너 이미지'},
            {id: 'offerwallPopupImage', name: '오퍼월 팝업 이미지'},
            {id: 'offerwallImage', name: '오퍼월 이미지'}
          ]}
          validate={required}
        />

        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
