import BookIcon from '@material-ui/icons/Book'
import PointCreate from './PointCreate'
import PointEdit from './PointEdit'
import PointShow from './PointShow'
import PointList from './PointList'

export default {
  list: PointList,
  create: PointCreate,
  edit: PointEdit,
  show: PointShow,
  icon: BookIcon
}
