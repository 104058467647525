import BookIcon from '@material-ui/icons/Book'
import OfferwallCreate from './OfferwallCreate'
import OfferwallEdit from './OfferwallEdit'
import OfferwallShow from './OfferwallShow'
import OfferwallList from './OfferwallList'

export default {
  create: OfferwallCreate,
  edit: OfferwallEdit,
  list: OfferwallList,
  show: OfferwallShow,
  icon: BookIcon
}
