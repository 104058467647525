import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {CardActions, Datagrid, DateField, Filter, List, SearchInput, SelectField, TextField} from 'react-admin'
import ReportListExcelExportButton from './ReportListExcelExportButton'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = ({props}) => (
  <CardActions>
    <ReportListExcelExportButton {...props} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label={props.translate('resources.ugcReports.fields.title')} source="search" alwaysOn />
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="isDelete" default={false} sortable={false} />

        <TextField source="ugcUserId" sortable={false} />
        <TextField source="ugcId" sortable={false} />
        <SelectField
          source="ugcType"
          choices={[
            {id: 'product', name: '제품 리뷰 신고'},
            {id: 'message', name: '쪽지 신고'},
            {id: 'productReview', name: '제품 리뷰 신고'},
            {id: 'productInquiry', name: '제품 문의 내역 신고'},
            {id: 'communityPosting', name: '커뮤니티/산책인증 포스팅 신고'},
            {id: 'userProfile', name: '유저 신고'},
            {id: 'talkComment', name: '커뮤니티/산책인증 댓글 신고'},
            {id: 'noticeComment', name: '공지사항/이벤트 댓글 신고'},
            {id: 'adComment', name: '광고 신고'}
          ]}
          sortable={false}
        />

        <TextField source="userId" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="description" sortable={false} />

        <SelectField
          source="category"
          choices={[
            {id: 'ad', name: '광고'},
            {id: 'spam', name: '스팸'},
            {id: 'inappropriateContent', name: '욕설/비방/혐오'}
          ]}
          sortable={false}
        />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
