import React from 'react'

import {
  CardActions,
  DateField,
  Show,
  TextField,
  translate,
  ArrayField,
  Datagrid,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  EditButton,
  NumberField
} from 'react-admin'

import ImageClickField from '../../custom/common/ImageClickField'
import CommentButton from './CommentButton'
import CommentListExcelExportButton from './CommentListExcelExportButton'
import EntryUserListExcelExportButton from './EntryUserListExcelExportButton'

const ShowActions = ({basePath, data, ...props}) => {
  return (
    <CardActions>
      <CommentListExcelExportButton {...props} />
      <EntryUserListExcelExportButton {...props} />
      <CommentButton record={data} type={'comment'} />
      <EditButton basePath={basePath} record={data} />
    </CardActions>
  )
}

const Expand = ({id, record, resource, ...props}) => {
  return (
    <>
      {record && (
        <ArrayField record={record} {...props} source="child" label={'답글'} addLabel={false}>
          <Datagrid>
            <TextField source="id" label={'ID'} sortable={false} />
            <ReferenceField source="userId" reference="users" linkType={'show'} basePath="/users" label={'유저'}>
              <TextField source="name" label="유저닉네임" />
            </ReferenceField>
            <TextField source="content" label={'내용'} sortable={false} />
            <DateField source="createdAt" label={'작성일'} locales="ko" showTime sortable={false} />
          </Datagrid>
        </ArrayField>
      )}
    </>
  )
}

class NoticeShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout>
          <Tab label={'기본 정보'}>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="content" />
            <NumberField source="rolling_order" />
            <ImageClickField label="대표 이미지" addLabel={true} source="titleImagePath" src="image" />
            <ImageClickField label="상세 이미지" addLabel={true} source="images" src="image" />

            <DateField source="createdAt" />
            <DateField source="startDate" />
            <DateField source="endDate" />
            <DateField source="periodStart" locales="ko" showTime sortable={true} />
            <DateField source="periodEnd" locales="ko" showTime sortable={true} />
          </Tab>

          <Tab label={'댓글 정보'}>
            <ArrayField source="comments" addLabel={false}>
              <Datagrid aria-expanded="false" expand={<Expand />}>
                <TextField source="id" label="댓글ID" sortable={false} />
                <ReferenceField source="userId" reference="users" linkType={'show'}>
                  <TextField source="name" label="유저닉네임" />
                </ReferenceField>
                <TextField source="content" label={'내용'} sortable={false} />
                <DateField source="createdAt" locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>

          <Tab label={'참여 내역'}>
            <ArrayField source="participation" addLabel={false}>
              <Datagrid aria-expanded="false" expand={<Expand />}>
                <TextField source="id" label="유저ID" sortable={false} />
                <ReferenceField source="userId" reference="users" linkType={'show'}>
                  <TextField source="name" label="유저닉네임" />
                </ReferenceField>
                <TextField source="content" label={'내용'} sortable={false} />
                <DateField source="createdAt" locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(NoticeShow)
