import BookIcon from '@material-ui/icons/Book'
import PlaceCreate from './PlaceCreate'
import PlaceEdit from './PlaceEdit'
import PlaceShow from './PlaceShow'
import PlaceList from './PlaceList'

export default {
  list: PlaceList,
  create: PlaceCreate,
  edit: PlaceEdit,
  show: PlaceShow,
  icon: BookIcon,
}
