import React from 'react'
import {
  ArrayField,
  CardActions,
  DateField,
  EditButton,
  ListButton,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  translate
} from 'react-admin'
import {TextArrayField} from "../../custom/common/TextArrayField"
import Button from "@material-ui/core/Button"

const MoveButton = ({record, history, ...rest}) => {
  return (
      <div>
        <Button onClick={() => {
          history.push(`/products?filter=${encodeURIComponent(JSON.stringify({
            sellerId: record.id,
          }))}`)
        }} color="primary">
          상품 리스트 확인
        </Button>
      </div>
  )
}

const SellerShowActions = ({basePath, data, history}) => {
  return (
      <CardActions>
        <ListButton basePath={basePath}/>
        <EditButton basePath={basePath} record={data}/>
        <MoveButton history={history} record={data}/>
      </CardActions>
  )
}

class SellerShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
        <Show {...props} actions={<SellerShowActions {...props}/>}>
          <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="address"/>
            <ArrayField source="tels">
              <TextArrayField/>
            </ArrayField>
            <NumberField source="minimumPrice"/>
            <NumberField source="packingCount"/>
            <NumberField source="todayFee"/>
            <TextField source="sellerId" />
            <DateField source="createdAt" locales='ko' showTime/>
          </SimpleShowLayout>
        </Show>
    )
  }
}

export default translate(SellerShow)
