import React from 'react'
import {Edit, SimpleForm, TextInput, NumberInput, BooleanInput} from 'react-admin'

import EditActions from '../../custom/common/EditActions'
import {required} from '../../validators'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions />}>
    <SimpleForm toolbar={<CreateToolbar />}>
      <TextInput source="name" validate={required} />
      <BooleanInput source="isActive" defaultValue={false} validate={[required]} />
      <NumberInput source="displayOrder" defaultValue={1000} validate={required} />
    </SimpleForm>
  </Edit>
)
