import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Field, propTypes, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import {withStyles} from '@material-ui/core/styles'

import {translate, userLogin} from 'react-admin'
import * as validators from '../../validators'

const styles = (theme) => ({
  main: {},
  card: {
    width: 320,
    marginTop: '15em'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    backgroundColor: '#303030'
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em',
    color: '#303030'
  },
  actions: {
    padding: '0 1em 1em 1em',
    color: '#ffffff'
  },
  logo: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  }
})

const renderInput = ({meta: {touched, error} = {}, input: {...inputProps}, ...props}) => (
  <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
)

class Login extends Component {
  login = (auth) => this.props.userLogin(auth, '/')

  render() {
    const {classes, handleSubmit, isLoading, translate} = this.props
    return (
      <div>
        <div className={classes.logo}>
          <img src={process.env.PUBLIC_URL + '/favicon.ico'} width={64} height={64} alt="logo" />
        </div>
        {/*<div className={classes.avatar}>*/}
        {/*  <Avatar className={classes.icon}>*/}
        {/*    /!*<LockIcon/>*!/*/}
        {/*  </Avatar>*/}
        {/*</div>*/}
        <form onSubmit={handleSubmit(this.login)}>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                name="userName"
                component={renderInput}
                validate={[validators.required, validators.userName]}
                label={translate('auth.userName')}
                disabled={isLoading}
              />
            </div>
            <div className={classes.input}>
              <Field
                name="password"
                component={renderInput}
                validate={[validators.required, validators.password]}
                label={translate('auth.password')}
                type="password"
                disabled={isLoading}
              />
            </div>
          </div>
          <CardActions className={classes.actions}>
            <Button
              style={{
                color: '#ffffff',
                backgroundColor: '#303030'
              }}
              variant="contained"
              type="submit"
              disabled={isLoading}
              className={classes.button}
              fullWidth
            >
              {isLoading && <CircularProgress size={25} thickness={2} />}
              {translate('auth.signIn')}
            </Button>
          </CardActions>
        </form>
        <h6 align="center">(로딩 지속시 '새로고침'을 눌러 화면을 갱신하세요.)</h6>
      </div>
    )
  }
}

Login.propTypes = {
  ...propTypes,
  authProvider: PropTypes.func,
  classes: PropTypes.object,
  previousRoute: PropTypes.string,
  translate: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({isLoading: state.admin.loading > 0})

const enhance = compose(
  translate,
  reduxForm({
    form: 'signIn'
  }),
  connect(mapStateToProps, {userLogin}),
  withStyles(styles)
)

export default enhance(Login)
