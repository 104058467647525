import React from 'react'
import {
    BooleanInput,
    DisabledInput,
    Edit, ImageField, ImageInput,
    SimpleForm,
    TextInput,
} from 'react-admin'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import {required} from "../../validators"

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <DisabledInput source='id'/>
      <TextInput source="name" validate={required}/>

      <ImageInput source="imagePath" accept="image/*" validate={[required]}>
          <ImageField source="image" title="title"/>
      </ImageInput>

      <BooleanInput source="isActive" defaultValue={false} validate={[required]}/>
    </SimpleForm>
  </Edit>
)
