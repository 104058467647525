import React from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button} from 'react-admin'
import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const EventListExcelExportButton = (props) => {
  const excelExport = async () => {
    const dataArr = [
      [
        'ID',
        '형태',
        '제목',
        '내용',
        '누적 조회수',
        '누적 좋아요 수',
        '누적 댓글 수',
        '시작 날짜',
        '종료 날짜',
        '오픈 날짜',
        '마감 날짜',
        '생성일'
      ]
    ]
    const {
      data: {data}
    } = await dataProvider('EXCEL', 'events')

    data.forEach((event, index) => {
      try {
        dataArr.push([
          event.id,
          event.form,
          event.title,
          event.content,
          event.totalReadCount,
          event.totalLikeCount,
          event.totalCommentCount,
          event.startDate,
          event.endDate,
          event.periodStart,
          event.periodEnd,
          new Date(event.createdAt).toLocaleString()
        ])
      } catch (e) {
        console.log(e)
      }
    })
    excelDownload(dataArr, '이벤트 리스트 목록')
  }
  return (
    <Button label="전체 리스트 다운로드" onClick={excelExport}>
      <ImportExportIcon />
    </Button>
  )
}

export default EventListExcelExportButton
