import BookIcon from '@material-ui/icons/Book'
import AdCreate from './AdCreate'
import AdShow from './AdShow'
import AdEdit from './AdEdit'
import AdList from './AdList'

export default {
  create: AdCreate,
  edit: AdEdit,
  list: AdList,
  show: AdShow,
  icon: BookIcon
}
