import React from 'react'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {crudGetList, translate} from 'ra-core'
import {Button as AdminButton, UPDATE} from 'react-admin'
import Button from '@material-ui/core/Button'
import BalanceBook from '@material-ui/icons/Book'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {dataProvider} from '../../App'
import {connect} from 'react-redux'

const styles = () => ({
  dialog: {
    width: '80%'
  },
  button: {}
})

class BalanceButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDone = this.handleDone.bind(this)
  }

  handleClick() {
    this.setState({
      isOpen: true,
      isOpenDelivery: false,
      value: null
    })
  }

  handleCancel() {
    this.setState({isOpen: false})
  }

  async handleDone() {
    const props = this.props
    const rows = props.selectedIds.map((value) => {
      return props.data[value]
    })

    if (!rows.length) return

    const balanceIds = []
    rows.forEach((e) => {
      if (e.id) balanceIds.push(e.productOrderId)
    })

    await dataProvider(UPDATE, 'balanceAccount', {
      ids: balanceIds,
      page: props.page,
      perPage: props.perPage,
      startDate: props.filterValues.startDate,
      endDate: props.filterValues.endDate
    })
    window.location.reload(true)
    this.setState({isOpen: false})
  }

  render() {
    const {isOpen} = this.state
    const {classes, selectedIds} = this.props

    return (
      <div>
        <AdminButton disabled={!selectedIds.length} label="정산" onClick={this.handleClick}>
          <BalanceBook />
        </AdminButton>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogTitle id="confirmation-dialog-title">정산을 진행하시겠습니까?</DialogTitle>

          <DialogContent></DialogContent>

          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              취소
            </Button>
            <Button onClick={this.handleDone} color="primary">
              진행
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default compose(
  connect(null, {
    dispatchCrudGetList: crudGetList
  }),
  translate,
  withStyles(styles)
)(BalanceButton)
