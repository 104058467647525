import React from 'react';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {Button,} from 'react-admin';
import {dataProvider} from "../../App"
import {excelDownload} from "../../custom/common/excelDownload"

const TalkListExcelExportButton = props => {

  const excelExport = async () => {
    const dataArr = [['ID', '유저ID', '닉네임', '게시글ID', '댓글 내용', '작성일']]
    const {data:{data}} = await dataProvider('EXCEL', 'comments')

    data.forEach((cc, index) => {
    try {
      dataArr.push([
        cc.id,
        cc.userId,
        cc.name,
        cc.talkId,
        cc.content,
        new Date(cc.createdAt).toLocaleString()
      ])
    } catch(e){
      console.log(e)
    }
    })
    excelDownload(dataArr, '게시글 리스트 목록')
  }
  return (
    <Button
      label="전체 리스트 다운로드"
      onClick={excelExport}
    >
    <ImportExportIcon/>
    </Button>
  );
};

export default TalkListExcelExportButton;
