import React, {useState} from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button, Loading} from 'react-admin'
import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const ListExcelExportButton = () => {
  const [loading, setLoading] = useState(false)

  const excelExport = async () => {
    try {
      setLoading(true)
      const excelData = [
        [
          'ID',
          '업체명',
          '주문날짜',
          '대주문번호',
          '상품주문번호',
          '상품명',
          '옵션명',
          '제품가',
          '옵션가',
          '수량',
          '주문금액',
          '사용포인트',
          '배송비',
          '실결제금액',
          '수수료',
          '정산금액(부가세포함)',
          '배송완료일',
          '환불계좌',
          '정산 여부'
        ]
      ]
      const retRows = await dataProvider('EXCEL', 'balanceAccount')

      let row
      for (let i = 0; i < retRows.data.length; i++) {
        row = retRows.data[i]
        excelData.push([
          row.id,
          row.sellerName, //업체명
          row.createdAt, //주문날짜

          String(row.orderId),
          String(row.productOrderId),

          row.productName, //상품명
          row.optionName, //옵션명

          parseInt(row.productSalePrice.replace(',', '')), //제품가
          parseInt(row.optionSalePrice.replace(',', '')), //옵션가

          parseInt(row.quantity.replace(',', '')), //수량
          parseInt(row.amount.replace(',', '')), //주문 금액

          row.point, //사용 포인트
          parseInt(row.deliveryFee.replace(',', '')), //배송비

          parseInt(row.userPayment.replace(',', '')), //실결제 금액
          parseInt(row.fee.replace(',', '')), //수수료

          parseInt(row.balance.replace(',', '')), //정산 금액
          row.deliveryDate != null ? new Date(row.deliveryDate).toLocaleString() : '',

          row.refundAccount, //환불 계좌
          row.balanced
        ])
      }
      setLoading(false)
      excelDownload(excelData, `정산리스트-${new Date().toLocaleDateString()}.xlsx`)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Button label="전체 리스트 다운로드" onClick={excelExport}>
        {loading ? (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%'
            }}
          >
            <Loading style={{position: 'relative', zIndex: '1000'}} className="target" />
          </div>
        ) : (
          <ImportExportIcon />
        )}
      </Button>
    </>
  )
}

export default ListExcelExportButton
