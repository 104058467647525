import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {CardActions, CreateButton, Datagrid, DateField, Filter, List, SearchInput, TextField} from 'react-admin'
import Button from '@material-ui/core/Button'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = ({basePath}) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label={'업체 명'} source="search" alwaysOn />
  </Filter>
)

const MoveButton = ({record, history, ...rest}) => {
  return (
    <div>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          history.push(
            `/products?filter=${encodeURIComponent(
              JSON.stringify({
                sellerId: record.id
              })
            )}`
          )
        }}
        color="primary"
      >
        상품 리스트 확인
      </Button>
    </div>
  )
}

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="minimumPrice" sortable={false} />
        <TextField source="packingCount" sortable={false} />
        <TextField source="sellerId" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
        <MoveButton {...props} />
      </Datagrid>
    </List>
  )
})
