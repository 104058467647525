import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {change, isSubmitting, submit} from 'redux-form'
import {
  BooleanInput,
  Button,
  CREATE,
  fetchEnd,
  fetchStart,
  FileField,
  FileInput,
  LongTextInput,
  maxLength,
  NumberInput,
  required,
  SaveButton,
  showNotification,
  SimpleForm
} from 'react-admin'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {readExcel} from '../../custom/common/readExcel'
import {dataProvider} from '../../App'

class PointBundleButton extends Component {
  state = {
    error: false,
    showDialog: false
  }

  handleClick = () => {
    this.setState({showDialog: true})
  }

  handleCloseClick = () => {
    this.setState({showDialog: false})
  }

  handleSaveClick = () => {
    const {submit} = this.props
    submit('post-quick-create')
  }

  handleSubmit = async (values) => {
    try {
      const {fetchStart, fetchEnd, showNotification} = this.props
      const excel = await readExcel(values.excel)

      const isHead = excel[0][0].userId
      if (!isHead) throw new Error('양식 오류!')
      let isEachPoint = excel[0][0].point
      if (values.isEachPoint !== !!isEachPoint) throw new Error('양식과 설정 조건 불일치!')

      const xls = excel[0].sort((a, b) => {
        if (a.userId < b.userId) return -1
        else if (a.userId > b.userId) return 1
        return 0
      })

      if (isEachPoint) {
        values.idPoints = xls.map((e) => {
          const userId = Number(e.userId)
          const point = Number(e.point)
          if (userId && typeof point === 'number') return {userId, point}
          else throw new Error('양식 불일치~')
        })
      } else {
        const userIds = xls.map((row) => row.userId)
        values.userIds = userIds.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
      }
      delete values.excel
      fetchStart()
      dataProvider(CREATE, 'points', {data: values})
        .then(({data}) => {
          window.location.reload()
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    } catch (e) {
      console.error(e)
      alert('비정상 요청입니다. 요청 옵션, 파일 포맷을 다시 확인해 보세요.')
    }
  }

  render() {
    const {showDialog} = this.state
    const {isSubmitting} = this.props

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="포인트 엑셀 등록">
          <NoteAddIcon />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="포인트 지급">
          <DialogTitle>포인트 지급</DialogTitle>
          <DialogContent>
            <SimpleForm form="post-quick-create" resource="posts" onSubmit={this.handleSubmit} toolbar={null}>
              <BooleanInput label="알림톡 발송" source="isNotiTalk" defaultValue={false} />
              <FileInput
                source="excel"
                label="엑셀"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , .xlsx"
                placeholder={<p>엑셀을 업로드 해주세요</p>}
              >
                <FileField source="src" title="title" validate={required} />
              </FileInput>
              <LongTextInput label="이벤트 내용" source="eventName" validate={[required(), maxLength(100)]} />
              <BooleanInput label="포인트 개별 등록" source="isEachPoint" defaultValue={false} />
              <NumberInput label="포인트" source="point" validate={[required()]} defaultValue={0} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  isSubmitting: isSubmitting('post-quick-create')(state)
})

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
}

export default connect(mapStateToProps, mapDispatchToProps)(PointBundleButton)
