import BookIcon from '@material-ui/icons/Book'
import CommunityPopularShow from './CommunityPopularShow'
import CommunityPopularEdit from './CommunityPopularEdit'
import CommunityPopularList from './CommunityPopularList'

export default {
  edit: CommunityPopularEdit,
  list: CommunityPopularList,
  show: CommunityPopularShow,
  icon: BookIcon
}
