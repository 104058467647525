import React from 'react'
import {
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  NumberInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm
          toolbar={<CreateToolbar/>}>
        <TextInput source="name" validate={required}/>
        <ImageInput source="imagePath" accept="image/*" validate={required}>
          <ImageField source="src" title="title"/>
        </ImageInput>
        <NumberInput source='order' defaultValue={1000} validate={required}/>
        <BooleanInput source="onSale" defaultValue={true} validate={required}/>
      </SimpleForm>
    </Create>
)
