import BookIcon from '@material-ui/icons/Book'
import SeedCampaignInquiryCategoryCreate from './SeedCampaignInquiryCategoryCreate'
import SeedCampaignInquiryCategoryEdit from './SeedCampaignInquiryCategoryEdit'
import SeedCampaignInquiryCategoryShow from './SeedCampaignInquiryCategoryShow'
import SeedCampaignInquiryCategoryList from './SeedCampaignInquiryCategoryList'

export default {
  list: SeedCampaignInquiryCategoryList,
  create: SeedCampaignInquiryCategoryCreate,
  edit: SeedCampaignInquiryCategoryEdit,
  show: SeedCampaignInquiryCategoryShow,
  icon: BookIcon
}
