import React from 'react'

import {DateField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin'
import {CardActions, DeleteButton, ListButton} from 'ra-ui-materialui'
import CommentButton from './CommentButton'

const ShowActions = ({basePath, data, resource}) => {
  return (
    <CardActions>
      <ListButton basePath={basePath} />
      <CommentButton record={data} />
      <DeleteButton basePath={basePath} resource={resource} record={data} />
    </CardActions>
  )
}

export const NoticeCommentsShow = (props) => {
  return (
    <Show {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="userId" reference="users" allowEmpty linkType={'show'}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField source="noticeId" reference="notices" allowEmpty linkType={'show'}>
          <TextField source="id" />
        </ReferenceField>

        <TextField source="title" label="이벤트 제목" />

        <TextField source="content" />
        <DateField source="createdAt" locales="ko" showTime />
      </SimpleShowLayout>
    </Show>
  )
}
