import React from 'react'
import {
  TextField,
  BooleanInput,
  ImageField,
  LongTextInput,
  SelectInput,
  SimpleForm,
  ImageInput,
  NumberInput,
  ReferenceInput,
  EditActions,
  Edit
} from 'react-admin'
import {required} from '../../validators'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => {
  return (
    <Edit {...props} actions={<EditActions />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceInput label="체험단" source="seedCampaignId" reference="seedCampaign" validate={required}>
          <SelectInput optionText="title" />
        </ReferenceInput>

        <NumberInput label="유저 ID" source="userId" alwaysOn />

        <LongTextInput source="connectedUrl" defaultValue={''} />

        <ImageInput source="titleImage" accept="image/*" validate={required}>
          <ImageField source="image" title="title" />
        </ImageInput>

        <br />
        <NumberInput source="displayOrder" defaultValue={1000} validate={required} />

        <BooleanInput source="isActive" validate={[required]} defaultValue={true} />
        <TextField label="* 비활성화 시 체험단 메인 화면에서 '베스트 리뷰' 영역에 미표출됩니다." />
      </SimpleForm>
    </Edit>
  )
}
