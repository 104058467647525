import BookIcon from '@material-ui/icons/Book'
import FaciltieCreate from './FacilitieCreate'
import FaciltieEdit from './FacilitieEdit'
import FaciltieShow from './FacilitieShow'
import FaciltieList from './FacilitieList'

export default {
  list: FaciltieList,
  create: FaciltieCreate,
  edit: FaciltieEdit,
  show: FaciltieShow,
  icon: BookIcon,
}
