import React from 'react'
import {Show, SimpleShowLayout, TextField, translate} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'

class PopupShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="description" />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(PopupShow)
