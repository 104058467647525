import React from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button} from 'react-admin'
import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const ReportListExcelExportButton = (props) => {
  const excelExport = async () => {
    const dataArr = [
      [
        'ID',
        '삭제 여부',
        '신고 대상 유저 ID',
        '신고글 ID',
        '신고 대상 분류',
        '신고자 ID',
        '제목',
        '내용',
        '신고 타입',
        '생성일'
      ]
    ]
    const {
      data: {data}
    } = await dataProvider('EXCEL', 'ugcReports')

    data.forEach((report, index) => {
      try {
        dataArr.push([
          report.id,
          report.isDelete,
          report.ugcUserId,
          report.ugcId,
          report.ugcType,
          report.userId,
          report.title,
          report.description,
          report.category,
          report.createdAt
        ])
      } catch (err) {
        console.log(err)
      }
    })
    excelDownload(dataArr, '댓글신고 관리 리스트 목록')
  }
  return (
    <Button label="전체 리스트 다운로드" onClick={excelExport}>
      <ImportExportIcon />
    </Button>
  )
}

export default ReportListExcelExportButton
