import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import Jimp from 'jimp'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {ImageParse} from './ImageMethod'

export async function request(type, params) {
  let url = '/admin/v3/pointExchange/products'
  let options = {}

  switch (type) {
    case UPDATE:
    case CREATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'

      if (params.data.returnPolicy && !params.data.returnPolicy.length) delete params.data.returnPolicy
      if (params.data.exchangePolicy && !params.data.exchangePolicy.length) delete params.data.exchangePolicy

      options.data = params.data
      let data = {...params.data}

      if (options.data.titleImagePath.image) {
        if (options.data.titleImagePath.image.substring(0, 4) === 'http') {
          options.data.titleImagePath.image = ImageParse(options.data.titleImagePath.image, 'products')
        }
        options.data = await uploadToS3(data, 'titleImagePath', 'products', 'titleImagePath')
      }

      options.data = await uploadToS3(data, 'images', 'products', 'images')

      if (options.data.todayFeeImage) {
        if (options.data.todayFeeImage.image.substring(0, 4) === 'http') {
          options.data.todayFeeImage.image = ImageParse(options.data.todayFeeImage.image, 'products')
        }
        options.data = await uploadToS3(data, 'todayFeeImage', 'products', 'todayFeeImage')
      }

      options.data.originalPrice = options.data.price
      options.data.discountRate = 0
      options.data.usePoint = 100
      options.data.type = 'pointExchange'

      options = await cutImages(options, 'detailImages')
      if (options.data.returnPolicy) options = await cutImages(options, 'returnPolicy')
      if (options.data.exchangePolicy) options = await cutImages(options, 'exchangePolicy')

      data = options.data
      options.data = await uploadToS3(data, 'detailImages', 'products', 'detailImages')
      options.data = await uploadToS3(data, 'returnPolicy', 'products', 'returnPolicy')
      options.data = await uploadToS3(data, 'exchangePolicy', 'products', 'exchangePolicy')

      options.data.titleImagePath = options.data.titleImagePath.image

      if (options.data.todayFeeImage) options.data.todayFeeImage = options.data.todayFeeImage.image

      if (options.data.images) {
        options.data.images = options.data.images.map((value) => value.image)
        options.data.images = options.data.images.map((v) => {
          if (v.substring(0, 4) === 'http') v = ImageParse(v, 'products')
          return v
        })
      }

      if (options.data.detailImages) {
        options.data.detailImages = options.data.detailImages.map((value) => value.image)
        options.data.detailImages = options.data.detailImages.map((v) => {
          if (v.substring(0, 4) === 'http') v = ImageParse(v, 'products')
          return v
        })
      }

      if (options.data.returnPolicy) {
        options.data.returnPolicy = options.data.returnPolicy.map((value) => value.image)
        options.data.returnPolicy = options.data.returnPolicy.map((v) => {
          if (v.substring(0, 4) === 'http') v = ImageParse(v, 'products')
          return v
        })
      }

      if (options.data.exchangePolicy) {
        options.data.exchangePolicy = options.data.exchangePolicy.map((value) => value.image)
        options.data.exchangePolicy = options.data.exchangePolicy.map((v) => {
          if (v.substring(0, 4) === 'http') v = ImageParse(v, 'products')
          return v
        })
      }
      break

    case 'DUPLICATE': {
      options.method = 'POST'

      if (params.data.returnPolicy && !params.data.returnPolicy.length) delete params.data.returnPolicy
      if (params.data.exchangePolicy && !params.data.exchangePolicy.length) delete params.data.exchangePolicy
      options.data = params.data

      let data = {...params.data}

      if (options.data.titleImagePath.image) {
        if (options.data.titleImagePath.image.substring(0, 4) === 'http') {
          options.data.titleImagePath.image = ImageParse(options.data.titleImagePath.image, 'products')
        }
        options.data = await uploadToS3(data, 'titleImagePath', 'products', 'titleImagePath')
      }

      options.data = await uploadToS3(data, 'images', 'products', 'images')

      if (options.data.todayFeeImage) {
        if (options.data.todayFeeImage.image.substring(0, 4) === 'http') {
          options.data.todayFeeImage.image = ImageParse(options.data.todayFeeImage.image, 'products')
        }
        options.data = await uploadToS3(data, 'todayFeeImage', 'products', 'todayFeeImage')
      }
      options.data.originalPrice = options.data.price
      options.data.discountRate = 0
      options.data.usePoint = 100
      options.data.type = 'pointExchange'

      options = await cutImages(options, 'detailImages')
      if (options.data.returnPolicy) options = await cutImages(options, 'returnPolicy')
      if (options.data.exchangePolicy) options = await cutImages(options, 'exchangePolicy')

      data = options.data
      options.data = await uploadToS3(data, 'detailImages', 'products', 'detailImages')
      options.data = await uploadToS3(data, 'returnPolicy', 'products', 'returnPolicy')
      options.data = await uploadToS3(data, 'exchangePolicy', 'products', 'exchangePolicy')

      options.data.titleImagePath = options.data.titleImagePath.image

      if (options.data.todayFeeImage) options.data.todayFeeImage = options.data.todayFeeImage.image

      if (options.data.images) {
        options.data.images = options.data.images.map((value) => value.image)
        options.data.images = options.data.images.map((v) => {
          if (v.substring(0, 4) === 'http') v = ImageParse(v, 'products')
          return v
        })
      }

      if (options.data.detailImages) {
        options.data.detailImages = options.data.detailImages.map((value) => value.image)
        options.data.detailImages = options.data.detailImages.map((v) => {
          if (v.substring(0, 4) === 'http') v = ImageParse(v, 'products')
          return v
        })
      }

      if (options.data.returnPolicy) {
        options.data.returnPolicy = options.data.returnPolicy.map((value) => value.image)
        options.data.returnPolicy = options.data.returnPolicy.map((v) => {
          if (v.substring(0, 4) === 'http') v = ImageParse(v, 'products')
          return v
        })
      }

      if (options.data.exchangePolicy) {
        options.data.exchangePolicy = options.data.exchangePolicy.map((value) => value.image)
        options.data.exchangePolicy = options.data.exchangePolicy.map((v) => {
          if (v.substring(0, 4) === 'http') v = ImageParse(v, 'products')
          return v
        })
      }

      if (options.data.options) {
        options.data.options = options.data.options.map((option) => {
          if (option.id) delete option.id
          return option
        })
      }
      break
    }

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        type: 'pointExchange',
        start: (page - 1) * perPage,
        perPage
      }
      break

    case GET_MANY:
      options.params = {ids: params.ids}
      break

    case GET_ONE:
      url += `/${params.id}`
      // eslint-disable-next-line
      const productId = params.id
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    case 'EXCEL':
      options.params = {
        start: 0,
        perPage: 1000000
      }
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  if (data.keyWords === null) data.keyWords = ''

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_ONE:
      if (data.titleImagePath) {
        data.titleImagePath = convertImagePath(data.titleImagePath, 'large')
        const image = data.titleImagePath
        data.titleImagePath = {}
        data.titleImagePath.image = image
      }

      if (data.todayFeeImage) {
        data.todayFeeImage = convertImagePath(data.todayFeeImage, 'large')
        const image = data.todayFeeImage
        data.todayFeeImage = {}
        data.todayFeeImage.image = image
      }

      data.images = data.images.map((value) => {
        return {image: convertImagePath(value, 'large')}
      })

      data.detailImages = data.detailImages.map((value) => {
        return {image: convertImagePath(value, 'large')}
      })

      if (data.returnPolicy) {
        data.returnPolicy = data.returnPolicy.map((value) => {
          return {image: convertImagePath(value, 'large')}
        })
      }

      if (data.exchangePolicy) {
        data.exchangePolicy = data.exchangePolicy.map((value) => {
          return {image: convertImagePath(value, 'large')}
        })
      }
      if (data.exhibitionIds == null) delete data.exhibitionIds

      ret = {data}

      break

    case GET_LIST:
      data.data.forEach((row) => (row.titleImagePath = convertImagePath(row.titleImagePath, 'large')))
      ret = data
      break

    case GET_MANY:
      data.forEach((row) => (row.titleImagePath = convertImagePath(row.titleImagePath, 'large')))
      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break
    default:
      if (data) ret = {data}
      break
  }

  return ret
}

export const cutImages = async (options, type) => {
  options.data[type] = await Promise.all(
    options.data[type].map(async (image) => {
      let imageArray = []
      if (image.rawFile) {
        const rawFile = image
        const longImageResize = await Jimp.read(rawFile.rawFile.preview)
        longImageResize.resize(750, Jimp.AUTO)
        if (longImageResize.bitmap.height > 750) {
          const count = Math.ceil(longImageResize.bitmap.height / 750)
          for (let i = 0; i < count; i++) {
            const cropImage = longImageResize.clone()
            if (i === count - 1 && count !== 1) cropImage.crop(0, i * 750, 750, longImageResize.bitmap.height % 750)
            else if (count === 1) cropImage.crop(0, i * 750, 750, 750)
            else cropImage.crop(0, i * 750, 750, 750)
            const buffer = await cropImage.getBufferAsync(rawFile.rawFile.type)
            await cropImage.getBufferAsync(rawFile.rawFile.type)
            const newRawFile = {rawFile: {preview: buffer, type: image.rawFile.type}}
            imageArray.push(newRawFile)
          }
        } else {
          imageArray.push({
            rawFile: {preview: await longImageResize.getBufferAsync(rawFile.rawFile.type), type: image.rawFile.type}
          })
        }
      } else {
        imageArray = image
      }
      return imageArray
    })
  )
  options.data[type] = options.data[type].flat()
  return options
}
