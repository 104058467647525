import ExhibitionCreate from './ExhibitionCreate'
import ExhibitionEdit from './ExhibitionEdit'
import ExhibitionList from './ExhibitionList'
import ExhibitionShow from './ExhibitionShow'

export default {
  list: ExhibitionList,
  create: ExhibitionCreate,
  edit: ExhibitionEdit,
  show: ExhibitionShow
}
