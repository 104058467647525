import React from 'react'
import {
  ArrayInput,
  Create,
  maxLength,
  maxValue,
  minLength,
  minValue,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'
import {required, tel, userName, password} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => {
  return (
      <Create {...props} actions={<CreateActions/>}>
        <SimpleForm
            toolbar={<CreateToolbar/>}>
          <TextInput source="name" validate={[required, minLength(1), maxLength(100)]}/>
          <TextInput source="address" validate={[required]}/>
          <ArrayInput source="tels" validate={required}>
            <SimpleFormIterator>
              <TextInput label='번호' format={(value) => (typeof value === 'object' ? '' : value)}
                         validate={[required, tel]}/>
            </SimpleFormIterator>
          </ArrayInput>
          <NumberInput source="minimumPrice" defaultValue={0} validate={[required]}/>
          <NumberInput source="packingCount" defaultValue={1} validate={[required, minValue(1)]}/>
          <NumberInput source="todayFee" defaultValue={0}
                       validate={[required, minValue(0), maxValue(200000)]}/>

          <TextInput source="sellerId" validate={[required, userName]}/>
          <TextInput source="password" validate={[required, password]}/>
        </SimpleForm>
      </Create>
  )
}
