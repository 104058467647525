import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {change, isSubmitting, submit} from 'redux-form'
import {
  Button,
  fetchEnd,
  fetchStart,
  FileField,
  FileInput,
  LongTextInput,
  TextInput,
  maxLength,
  SaveButton,
  showNotification,
  SimpleForm,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  minLength,
  BooleanInput
} from 'react-admin'
import SendIcon from '@material-ui/icons/Send'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {readExcel} from '../../custom/common/readExcel'
import {dataProvider} from '../../App'
import {required, uri} from '../../validators'

class UserNotificationButton extends Component {
  state = {
    error: false,
    showDialog: false,
    showExcelInput: false
  }

  handleClick = () => {
    this.setState({showDialog: true})
  }

  handleCloseClick = () => {
    this.setState({showDialog: false})
  }

  handleSaveClick = () => {
    const {submit} = this.props
    submit('post-quick-create')
  }

  handleChange = (e) => {
    this.setState({showExcelInput: e.target.checked})
  }

  handleSubmit = async (values) => {
    const {fetchStart, fetchEnd, showNotification} = this.props
    if (!!values.allMessage) {
      values.users = []
    } else {
      const excel = await readExcel(values.excel)
      const users = excel[0].map((row) => {
        const ret = {}
        const keys = Object.keys(row)
        keys.forEach((k) => {
          if (!row[k]) throw new Error(`UndefinedValue - ${k}`)
          if (k === '유저 ID') ret.id = row[k]
          else ret[k] = row[k]
        })
        return ret
      })
      values.users = users.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
      delete values.excel
    }
    delete values.allMessage

    fetchStart()
    for (let i = 0; i < values.users.length; i += 200) {
      ;(function (i) {
        setTimeout(function () {
          const temp = {
            message: values.message,
            title: values.title,
            type: values.type,
            content: values.content,
            users: values.users.slice(i, i + 200)
          }

          dataProvider('PUSH', 'users', {data: temp})
            .then(({data}) => {
              // window.location.reload()
            })
            .catch((error) => {
              showNotification(error.message, 'error')
              this.setState({showDialog: false})
            })
            .finally(() => {
              fetchEnd()
            })
        }, 3000)
      })(i)
    }
    setTimeout(() => {
      window.location.reload()
    }, 200000)
  }
  render() {
    const {showDialog, showExcelInput} = this.state
    const {isSubmitting} = this.props

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="유저 PUSH">
          <SendIcon />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="유저 PUSH">
          <DialogTitle>유저 PUSH</DialogTitle>
          <DialogContent>
            <SimpleForm form="post-quick-create" resource="posts" onSubmit={this.handleSubmit} toolbar={null}>
              <BooleanInput source="allMessage" label="전체 Push" onChange={this.handleChange} defaultValue={false} />
              {!showExcelInput ? (
                <FileInput
                  source="excel"
                  label="엑셀"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , .xlsx"
                  placeholder={<p>엑셀을 업로드 해주세요</p>}
                >
                  <FileField source="src" title="title" validate={required} />
                </FileInput>
              ) : (
                ''
              )}

              <SelectInput
                source="type"
                choices={[
                  {id: 'myPage', name: '마이 페이지'},
                  {id: 'products', name: '쇼핑'},
                  {id: 'url', name: '링크'},
                  {id: 'talks', name: '게시판'},
                  {id: 'walks', name: '산책'},
                  {id: 'event', name: '기획전'},
                  {id: 'notice', name: '공지/이벤트'}
                ]}
                validate={required}
              />

              <FormDataConsumer>
                {({formData, ...rest}) => {
                  return (
                    <>
                      {formData && formData.type === 'event' && (
                        <ReferenceInput label="기획전" source="content" reference="exhibition" perPage={200}>
                          <SelectInput optionText="title" />
                        </ReferenceInput>
                      )}
                      {formData && formData.type === 'url' && (
                        <LongTextInput label="내용" source="content" validate={[required, uri, minLength(1)]} />
                      )}
                      {formData && formData.type === 'notice' && (
                        <TextInput
                          label="게시글ID"
                          source="content"
                          validate={[required, maxLength(40), minLength(1)]}
                        />
                      )}
                    </>
                  )
                }}
              </FormDataConsumer>
              <LongTextInput label="푸쉬 제목" source="title" validate={[required, maxLength(50)]} />
              <LongTextInput label="푸쉬 내용" source="message" validate={[required, maxLength(150)]} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  isSubmitting: isSubmitting('post-quick-create')(state)
})

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationButton)
