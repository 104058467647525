import * as XLSX from 'xlsx'

export const readExcel = (excel) => {
  return new Promise(function (resolve, reject) {
    const excelUrl = excel.src
    const oReq = new XMLHttpRequest()
    oReq.open('GET', excelUrl, true)
    oReq.responseType = 'arraybuffer'
    oReq.onload = function (e) {
      const arrayBuffer = oReq.response
      /* convert data to binary string */
      const data = new Uint8Array(arrayBuffer)

      const arr = []
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i])
      }

      const bstr = arr.join('')
      const cfb = XLSX.read(bstr, {type: 'binary'})

      const parsing = cfb.SheetNames.map(function (sheetName, index) {
        // Obtain The Current Row As CSV
        return XLSX.utils.sheet_to_json(cfb.Sheets[sheetName])
      })
      resolve(parsing)
    }
    oReq.send()
  })
}
