import React from 'react'
import {DateField, NumberField, SelectField, Show, SimpleShowLayout, TextField, translate} from 'react-admin'
import ManagerShowActions from '../../custom/common/ManagerShowActions'
import {required} from '../../validators'
import ImageClickField from '../../custom/common/ImageClickField'

class DesignSourceShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ManagerShowActions />}>
        <SimpleShowLayout>
          <NumberField source="id" defaultValue={1000} validate={required} />
          <TextField source="name" validate={required} />
          <ImageClickField addLabel={true} source="titleImage" src="image" />
          <SelectField
            source="category"
            label="대분류"
            choices={[
              {id: 'seedCampaign', name: '체험단'},
              {id: 'banners', name: '배너'},
              {id: 'popups', name: '팝업'},
              {id: 'offerwall', name: '오퍼월'}
            ]}
            validate={required}
          />
          <SelectField
            source="detailCategory"
            label="소분류"
            choices={[
              {id: 'channelPopupImage', name: '채널별 팝업 공지'},
              {id: 'additionalTitleImage', name: '캡션 이미지'},
              {id: 'offerwallBannerImage', name: '오퍼월 배너 이미지'},
              {id: 'offerwallPopupImage', name: '오퍼월 팝업 이미지'},
              {id: 'offerwallImage', name: '오퍼월 이미지'}
            ]}
            validate={required}
          />
          <DateField source="createdAt" locales="ko" showTime sortable={false} />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(DesignSourceShow)
