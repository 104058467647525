import BookIcon from '@material-ui/icons/Book'
import CommunityCategoryCreate from './CommunityCategoryCreate'
import CommunityCategoryEdit from './CommunityCategoryEdit'
import CommunityCategoryShow from './CommunityCategoryShow'
import CommunityCategoryList from './CommunityCategoryList'

export default {
  list: CommunityCategoryList,
  create: CommunityCategoryCreate,
  edit: CommunityCategoryEdit,
  show: CommunityCategoryShow,
  icon: BookIcon
}
