import React from 'react'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {crudDelete, crudGetOne, translate} from 'ra-core'
import {Button as AdminButton} from 'react-admin'
import Button from '@material-ui/core/Button'
import {CompareArrows} from '@material-ui/icons'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {connect} from 'react-redux'
import {dataProvider} from '../../App'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  dialog: {
    width: '80%'
  },
  button: {}
})

class SeedCampaignApplicantStatusButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      value: null,
      isConfirm: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }
  async updateApplicantStatus() {
    const {selectedIds, seedCampaignData} = this.props

    if (this.state.value) {
      await dataProvider('UPDATE_STATUS', 'seedCampaign', {
        id: seedCampaignData.id,
        data: {seedCampaignApplicantIds: selectedIds, status: this.state.value}
      })
    }
  }

  handleClick() {
    this.setState({
      isOpen: true,
      value: null,
      isConfirm: false
    })
  }

  handleCancel() {
    this.setState({isOpen: false})
  }

  async handleDone(e) {
    e.stopPropagation()
    const {onDone} = this.props

    await this.updateApplicantStatus()
    onDone()
    this.setState({isOpen: false})
  }
  async handleConfirm(e) {
    e.stopPropagation()
    const {onDone} = this.props

    await this.updateApplicantStatus()
    onDone()
    this.setState({isOpen: false})
  }
  handleChange(e, v) {
    this.setState({value: v})
  }

  render() {
    const {isOpen, isConfirm} = this.state
    const {classes, selectedIds} = this.props

    const choices = [
      {id: 'READY', name: '취소 (신청 전)'},
      {id: 'SELECTED', name: '선정'},
      {id: 'UNSELECTED', name: '미선정'}
      // {id: 'finish_payback_progress', name: '환급 진행 중'},
      // {id: 'finish_clear', name: '정상 종료'},
      // {id: 'finish_wrong', name: '비정상 종료'}
    ]
    // READY                     기본
    // APPLIED                     신청
    // SELECTED                  선정
    // UNSELECTED                미선정
    // '미션 제출 전'은 '선정'에서 '확인'을 눌렀을때 변경되는 값
    // MISSION_REQUIRED          미션 제출 전
    // '미션 심사 중'은 '미션 제출 전'에서 미션 제출을 마치고 난 뒤
    // MISSION_REVIEWED           미션 심사 중
    // '미션 반려'는 상세 페이지에서 확인하여 체크되게끔 진행
    // MISSION_REJECTED          미션 반려
    // FINISH_PAYBACK_PROGRESS   환급 진행 중
    // FINISH_CLEAR              정상 종료
    // FINISH_WRONG              비정상 종료
    return (
      <div>
        <AdminButton disabled={!selectedIds.length} label="상태 변경" onClick={this.handleClick}>
          <CompareArrows />
        </AdminButton>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogTitle id="confirmation-dialog-title">신청자 상태 변경</DialogTitle>
          <DialogContent>
            {isConfirm ? (
              <div>
                <Typography variant="body1" style={{display: 'inline-block'}}>
                  현재 선택하신 항목은 "{choices.find((choice) => choice.id === this.state.value).name}" 입니다.
                </Typography>
                <Typography variant="body1" style={{display: 'inline-block'}}>
                  정말 진행하시겠습니까?
                </Typography>
              </div>
            ) : (
              <RadioGroup aria-label="Status" name="status" value={this.state.value} onChange={this.handleChange}>
                {choices.map(({id, name}) => (
                  <FormControlLabel value={id} key={id} control={<Radio />} label={name} />
                ))}
              </RadioGroup>
            )}
          </DialogContent>
          <DialogActions>
            {isConfirm ? (
              <React.Fragment>
                <Button onClick={this.handleCancel} color="primary">
                  취소
                </Button>
                <Button onClick={this.handleConfirm} color="primary">
                  예
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button onClick={this.handleCancel} color="primary">
                  취소
                </Button>
                <Button onClick={() => this.setState({isConfirm: true})} color="primary">
                  변경
                </Button>
              </React.Fragment>
            )}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default compose(
  connect(null, {
    dispatchCrudGetOne: crudGetOne,
    dispatchCrudDelete: crudDelete
  }),
  translate,
  withStyles(styles)
)(SeedCampaignApplicantStatusButton)
