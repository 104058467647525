import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {CardActions, CreateButton, Datagrid, DateField, List, TextField, Filter} from 'react-admin'
const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const Actions = ({basePath, ...props}) => {
  const permissions = localStorage.getItem('auth')
  return <CardActions>{permissions !== 'manager' ? <CreateButton basePath={basePath} /> : null}</CardActions>
}

const ListFilter = (props) => (
  <Filter {...props}>
    {
      //<SearchInput label='제목' source="search" choices={choices.status} alwaysOn/>
    }
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" label="ID" sortable={true} />
        <TextField source="title" sortable={false} />
        <TextField source="landing" sortable={false} />
        <TextField source="type" sortable={false} />
        <TextField source="userClick" sortable={false} />
        <DateField source="startDate" locales="ko" showDate sortable={false} />
        <DateField source="endDate" locales="ko" showDate sortable={false} />
      </Datagrid>
    </List>
  )
})
