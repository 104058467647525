import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {isNull, omitBy} from 'lodash'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {ImageParse, ImageParseUpdate} from './ImageMethod'

let recentOptions
//eslint-disable-next-line
let eventId

function ViewOptionsItem(id, type, title, beforeSelection, afterSelection, expiredSelection) {
  this.id = id
  this.type = type
  this.title = title
  this.isSelected = false
  this.beforeSelection = beforeSelection
  this.afterSelection = afterSelection
  this.expiredSelection = expiredSelection
}

export async function request(type, params) {
  let url = '/admin/v3/events'
  let options = {}

  switch (type) {
    case UPDATE:
      url += `/${params.id}`
      params.data = omitBy(params.data, isNull)
    // eslint-disable-next-line no-fallthrough
    case CREATE:
      if (type === 'UPDATE') options.method = 'PUT'
      else options.method = 'POST'

      options.data = params.data
      const data = {...params.data}

      if (options.data.titleImagePath.image) {
        if (options.data.titleImagePath.image.substring(0, 5) === 'https') {
          options.data.titleImagePath.image = ImageParse(options.data.titleImagePath.image, 'notices')
        }
      }

      options.data = await uploadToS3(data, 'titleImagePath', 'notices', 'titleImagePath')
      options.data = await uploadToS3(data, 'images', 'notices', 'images')
      options.data = await uploadToS3(data, 'bottomImages', 'notices', 'bottomImages')

      const src = options.data.titleImagePath.image
      options.data.titleImagePath = src

      if (options.data.titleImagePath.substring(0, 5) === 'https') {
        options.data.titleImagePath = ImageParseUpdate(options.data.titleImagePath, 'notices')
      }

      if (data.images) {
        data.images = data.images.map((image) => {
          if (image.hasOwnProperty('path')) return image.path
          return image
        })
      }
      if (options.data.images) {
        options.data.images = options.data.images.map((image) => {
          if (image.hasOwnProperty('image')) return image.image
          return image
        })
      }
      if (data.bottomImages) {
        data.bottomImages = data.bottomImages.map((image) => {
          if (image.hasOwnProperty('path')) return image.path
          return image
        })
      }
      if (options.data.bottomImages) {
        options.data.bottomImages = options.data.bottomImages.map((image) => {
          if (image.hasOwnProperty('image')) return image.image
          return image
        })
      }

      if (!options.data.images) options.data.images = []
      if (!options.data.createdAt) options.data.createdAt = new Date().toISOString()
      if (!options.data.form) {
        options.data.form = 'general'
      }
      if (options.data.isCtaButtonAvailable) {
        options.data = await uploadToS3(data, 'urlImage', 'notices', 'urlImage')
        if (options.data.urlImage.image.substring(0, 5) === 'https') {
          options.data.urlImage.image = ImageParse(options.data.urlImage.image, 'urlImage')
        } else if (options.data.urlImage.image.substring(0, 4) === 'http') {
          options.data.urlImage.image = ImageParse(options.data.urlImage.image, 'urlImage')
        }
        options.data.viewOptions = []
        options.data.viewOptions.push({url: options.data.url, urlImage: options.data.urlImage})
      }

      if (options.data.viewOptions && options.data.viewOptions[0].url) {
        options.data = await uploadToS3(data, 'urlImage', 'notices', 'urlImage')
        if (options.data.urlImage.image.substring(0, 5) === 'https') {
          options.data.urlImage.image = ImageParse(options.data.urlImage.image, 'urlImage')
        } else if (options.data.urlImage.image.substring(0, 4) === 'http') {
          options.data.urlImage.image = ImageParse(options.data.urlImage.image, 'urlImage')
        }
        options.data.viewOptions = []
        options.data.viewOptions.push({url: options.data.url, urlImage: options.data.urlImage})
      }
      if (options.data.form === 'pick') {
        //추석이벤트를 위한 임시방안
        const itemList = []

        options.data.viewOptions = await Promise.all(
          options.data.viewOptions.map(async (item, index) => {
            if (item.beforeSelection.image.substring(0, 5) === 'https') {
              item.beforeSelection = ImageParse(item.beforeSelection.image, 'notices')
              item.afterSelection = ImageParse(item.afterSelection.image, 'notices')
              item.expiredSelection = ImageParse(item.expiredSelection.image, 'notices')
            } else if (item.beforeSelection.image.substring(0, 4) === 'http') {
              item.beforeSelection = ImageParse(item.beforeSelection.image, 'notices')
              item.afterSelection = ImageParse(item.afterSelection.image, 'notices')
              item.expiredSelection = ImageParse(item.expiredSelection.image, 'notices')
            }
            item = await uploadToS3(item, 'beforeSelection', 'notices', 'beforeSelection')
            item = await uploadToS3(item, 'afterSelection', 'notices', 'afterSelection')
            item = await uploadToS3(item, 'expiredSelection', 'notices', 'expiredSelection')

            item.beforeSelection = item.beforeSelection.image
            item.afterSelection = item.afterSelection.image
            item.expiredSelection = item.expiredSelection.image

            itemList.push(
              new ViewOptionsItem(
                index,
                item.type,
                item.title,
                item.beforeSelection,
                item.afterSelection,
                item.expiredSelection
              )
            )

            return itemList
          })
        )
        itemList.sort((a, b) => {
          return a.id - b.id
        })
        options.data.viewOptions = itemList
      }
      console.log(options.data)
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort

      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      recentOptions = options
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      options.params = {
        perPage: 1000
      }
      break

    case GET_ONE:
      url += `/${params.id}`
      //eslint-disable-next-line
      eventId = params.id
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    case 'COMMENT':
      url += `/${params.id}/comments`
      eventId = params.id
      options.method = 'GET'
      break

    case 'POST_COMMENT':
      url += `/${params.id}/comments`
      eventId = params.id
      options.data = params.data
      options.method = 'POST'
      break

    case 'EXCEL':
      options = recentOptions
      options.params.perPage = undefined
      break

    case 'ENTRY':
      url += `/${params.id}`
      eventId = params.id
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = data
      break

    case GET_LIST:
      data.data.forEach((row) => {
        if (row.titleImagePath !== '') {
          row.titleImagePath = convertImagePath(row.titleImagePath, 'large')
        }
      })
      ret = data
      break

    case GET_ONE:
      if (data.titleImagePath) {
        data.titleImagePath = convertImagePath(data.titleImagePath, 'large')
      }
      if (typeof data.titleImagePath === 'string') {
        const image = data.titleImagePath
        data.titleImagePath = {}
        data.titleImagePath.image = image
      }
      if (data.images) {
        data.images = data.images.map((image) => {
          return {path: image, image: convertImagePath(image, 'large')}
        })
      }
      if (data.bottomImages) {
        data.bottomImages = data.bottomImages.map((image) => {
          return {path: image, image: convertImagePath(image, 'large')}
        })
      }

      if (data.hasOwnProperty('comments')) {
        data.comments = data.comments.data
        data.comments.forEach((comment, index) => {
          if (comment.parentId) {
            data.comments.filter((filter, i) => {
              if (filter.id === comment.parentId) {
                if (!data.comments[i].hasOwnProperty('child')) data.comments[i].child = []
                data.comments[i].child.push(comment)
                delete data.comments[index]
                return filter
              }
              return false
            })

            return null
          }
          return comment
        })
        data.comments = data.comments.filter((n) => n)
      }
      ret = {data}
      console.log(ret)
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
